import React, { useState } from "react";
import { Layout, Menu, Button } from "antd";
import {
  ProjectOutlined,
  FileOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import logo from "../assets/logo.png";

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

const SidebarLayout = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={toggleSidebar}
      style={{ backgroundColor: "rgb(24, 32, 47)" }}
    >
      <div style={{ display: "flex", alignItems: "center", padding: "16px" }}>
        <img
          src={logo}
          alt="Logo"
          style={{ height: 32, marginRight: collapsed ? 0 : 12 }}
        />
        <div onClick={toggleSidebar} style={{ cursor: "pointer" }}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </div>
      </div>

      <Menu
        theme="dark"
        defaultSelectedKeys={["1"]}
        mode="inline"
        style={{ backgroundColor: "rgb(24, 32, 47)" }}
      >
        <SubMenu key="sub0" title="Projects" />
        {/* Project Menu */}
        <SubMenu key="sub1" icon={<ProjectOutlined />} title="Module">
          <Menu.Item key="1">Physical Risk</Menu.Item>
          <Menu.Item key="2">Transition Risk</Menu.Item>
          <Menu.Item key="3">Map Module</Menu.Item>
        </SubMenu>

        {/* Documents Menu */}
        <SubMenu key="sub2" icon={<FileOutlined />} title="Documents">
          <Menu.Item key="4">Document 1</Menu.Item>
          <Menu.Item key="5">Document 2</Menu.Item>
          <Menu.Item key="6">Document 3</Menu.Item>
          <Menu.Item key="7">Document 4</Menu.Item>
        </SubMenu>

        {/* Logout Button */}
        <Menu.Item key="logout" icon={<LogoutOutlined />}>
          Logout
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default SidebarLayout;
