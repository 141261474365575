// ChangelogPage.jsx
import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Container } from "react-bootstrap";

// Style Constants
const headerStyle = {
  textAlign: "center",
  fontSize: "56px",
  marginTop: "1%",
};

const featureBoxStyle = {
  backgroundColor: "rgba(51, 182, 121, 0.2)",
  border: "2px solid green",
  padding: "10px",
  marginBottom: "20px",
};

const improvementBoxStyle = {
  backgroundColor: "rgba(255, 235, 59, 0.2)",
  border: "2px solid #18202F",
  padding: "10px",
  marginBottom: "20px",
};

const listStyle = {
  listStyleType: "disc",
  paddingLeft: "20px",
  lineHeight: "2em",
};

const ChangelogPage = () => (
  <Container style={{ marginTop: "7%" }}>
    <br />
    <br />
    <br />
    <p style={headerStyle}>Change log</p>

    <VerticalTimeline layout={"2-columns"}>
      <VerticalTimelineElement
        className="vertical-timeline-element--update"
        contentArrowStyle={{ borderRight: "7px solid  #18202F" }}
        date="October 2024"
        iconStyle={{
          background: "#18202F",
          color: "#fff",
        }}
      >
        <h3 className="vertical-timeline-element-title">
          Version 4.0 - Feature Update
        </h3>

        {/* Added Features Section */}
        <div style={featureBoxStyle}>
          <h4>Added Features</h4>
          <ul style={listStyle}>
            <li>Map Module (Version 1.0)</li>
            <ul style={listStyle}>
              <li>Global coverage</li>
              <li>Create maps from 1km to 50 km</li>
              <li>Rainfall data and Storm surge data are available</li>
            </ul>
            <li>PDF reports (Version 1.0)</li>
            <ul style={listStyle}>
              <li>Generate report automatically</li>
            </ul>
          </ul>
        </div>

        {/* Improvements Section */}
        <div style={improvementBoxStyle}>
          <h4>Improvements</h4>
          <ul style={listStyle}>
            <li>Rainfall Flood</li>
            <ul style={listStyle}>
              <li>Houston - 1m</li>
              <li>New York - 1m</li>
              <li>Los Angeles - 1m</li>
            </ul>
            <li>Storm Surge Delta DEM</li>
            <ul style={listStyle}>
              <li>30m Globally</li>
            </ul>
            <li>SSP 5-year interval on dashboard</li>
            <li>Input CSV</li>
            <ul style={listStyle}>
              <li>
                Total inputs (gross area, total valuation, total content value)
                in input CSV
              </li>
            </ul>
          </ul>
        </div>
      </VerticalTimelineElement>
      {/* Version 3.0 */}
      <VerticalTimelineElement
        className="vertical-timeline-element--update"
        contentArrowStyle={{ borderRight: "7px solid  #18202F" }}
        date="May 2024"
        iconStyle={{
          background: "#18202F",
          color: "#fff",
        }}
      >
        <h3 className="vertical-timeline-element-title">
          Version 3.0 - Feature Update
        </h3>
        {/* Added Features Section */}
        <div style={featureBoxStyle}>
          <h4>Added Features</h4>
          <ul style={listStyle}>
            <li>Updated user interface</li>
            <li>Building level flood adaptation (Mitigation)</li>
            <li>Allows three (3) different levels of flood mitigation</li>
            <li>Integrated into both Hazard and Financial analysis</li>
            <li>Applicable for Rainfall and River Flood</li>
            <li>
              Building footprint database generated covering 1.3 billion
              buildings globally integrating Microsoft, Google, and HK footprint
              data
            </li>
            <li>
              New damage functions specific to asset types (infrastructure) and
              regions
            </li>
            <li>Ability to add basement as an asset-level variable</li>
            <li>Ability to share projects within an organization</li>
          </ul>
        </div>

        {/* Improvements Section */}
        <div style={improvementBoxStyle}>
          <h4>Improvements</h4>
          <ul style={listStyle}>
            <li>
              Enhancements to calculation of business interruption days for
              Floods and Typhoons
            </li>
          </ul>

          <h4>Hazard Resolution</h4>
          <ul style={listStyle}>
            <li>Storm Surge</li>
            <ul style={listStyle}>
              <li>30m Globally (FABDEM)</li>
            </ul>
            <li>Rainfall Flood</li>
            <ul style={listStyle}>
              <li>Malaysia, Singapore, Mongolia, Turkey - 30m</li>
              <li>Eastern Coast of Australia – 5m</li>
              <li>London, UK – 2m</li>
            </ul>
          </ul>
        </div>
      </VerticalTimelineElement>

      {/* Version 4.0 */}
      <VerticalTimelineElement
        className="vertical-timeline-element--update"
        contentArrowStyle={{ borderRight: "7px solid  #18202F" }}
        date="October 2024"
        iconStyle={{
          background: "#18202F",
          color: "#fff",
        }}
      >
        <h3 className="vertical-timeline-element-title">
          Version 4.0 - Feature Update
        </h3>

        {/* Added Features Section */}
        <div style={featureBoxStyle}>
          <h4>Added Features</h4>
          <ul style={listStyle}>
            <li>Map Module (Version 1.0)</li>
            <ul style={listStyle}>
              <li>Global coverage</li>
              <li>Create maps from 1km to 50 km</li>
              <li>Rainfall data and Storm surge data are available</li>
            </ul>
            <li>PDF reports (Version 1.0)</li>
            <ul style={listStyle}>
              <li>Generate report automatically</li>
            </ul>
          </ul>
        </div>

        {/* Improvements Section */}
        <div style={improvementBoxStyle}>
          <h4>Improvements</h4>
          <ul style={listStyle}>
            <li>Rainfall Flood</li>
            <ul style={listStyle}>
              <li>Houston - 1m</li>
              <li>New York - 1m</li>
              <li>Los Angeles - 1m</li>
            </ul>
            <li>Storm Surge Delta DEM</li>
            <ul style={listStyle}>
              <li>30m Globally</li>
            </ul>
            <li>SSP 5-year interval on dashboard</li>
            <li>Input CSV</li>
            <ul style={listStyle}>
              <li>
                Total inputs (gross area, total valuation, total content value)
                in input CSV
              </li>
            </ul>
          </ul>
        </div>
      </VerticalTimelineElement>
    </VerticalTimeline>
  </Container>
);

export default ChangelogPage;
