import React, { Component } from "react";

import {
  Header,
  Icon,
  Menu,
  Label,
  Button,
  Radio,
  Image,
  Form,
  Input,
  Modal,
  Popup,
  Select,
  Checkbox,
  Accordion,
  Dropdown,
  Card,
} from "semantic-ui-react";
import Mitigation from "../assets/Mitigation.png";
import Spinner from "./spinner";
import Tier from "./tier_component";
import LossPercentNewComponent from "./loss_percent_new_component";

import StickyBox from "react-sticky-box";
import rainfall from "../assets/rainfall_mt.png";
import flood from "../assets/flood_mt.png";
import fire from "../assets/fire_mt.png";
import HeatClusterMap from "./heat_cluster_map";
import RiverFloodClusterMap from "./river_cluster_map";
import drought from "../assets/drought_mt.png";
import storm from "../assets/storm_mt.png";
import heat from "../assets/heat_mt.png";
import wind from "../assets/wind_mt.png";
import { Column } from "@ant-design/charts";

import bgsummary from "../assets/summary-bg.png";
import divimg from "../assets/divimg.png";
import concimg from "../assets/concimg.png";
import MitigationBar from "./mitigation_bar";
import tr1 from "../assets/toprow1.png";
import tr2 from "../assets/toprow2.png";
import tr3 from "../assets/toprow3.png";
import tr4 from "../assets/toprow4.png";
import tr5 from "../assets/toprow5.png";
import TyphoonClusterMap from "./typhoon_cluster_map";
import DroughtClusterMap from "./drought_cluster_map";

import MapChart from "./MapChart";

import { Tooltip, Progress } from "antd";

import { ResponsiveBar } from "@nivo/bar";
import index from "../assets/crisk.png";
import { linearGradientDef } from "@nivo/core";
import { connect } from "react-redux";

import { project, auth, dashboard, locus, asset } from "../actions";
import Building from "./building";

import "./dashboard.css";

import {
  Tabs,
  Tab,
  Row,
  Col,
  Container,
  Breadcrumb,
  tooltip,
  ThemeProvider,
} from "react-bootstrap";
//import Detail from "./detail";
import Circle from "react-circle";

import RCPDonut from "./rcpdonut";
import legend from "../assets/legend_heatmap.png";
import legend_h from "../assets/legend_heatmap_h.png";
import hazard_h from "../assets/hazard_heatmap.png";

import YEARDonut from "./yeardonut";

import SingleRCPDonut from "./singlercpdonut";
import SingleYEARDonut from "./singleyeardonut";

import Heatmap from "./heatmap";
import Classicheatmap from "./classicheatmap";
import BarComponent from "./barcomponent";
import Progress_bar from "./progress_bar";
import Progress_bar_asset from "./individual_progress_bar";
import LossComponent from "./losscomponent";
import DemoColumn from "./year_donut";
import { Table } from "antd";

import Map from "./map";
import WorldMap from "./worldmap1";
import RiskMap from "./riskmap";
import CandleStick from "./candlestick";
import PopupMap from "./map1";
import EditableTable from "./table";
import EditableLossTable from "./losstable";
import EditableValuationTable from "./valuationtable";
import EditableClimateTable from "./climatetable";
import MdryearDonut from "./mdryeardonut";
import MdrrcpDonut from "./mdrrcpdonut";
import SingleMdryearDonut from "./singlemdryeardonut";
import SingleMdrrcpDonut from "./singlemdrrcpdonut";
import LandslideClusterMap from "./lanslide_cluster_map";
import SummaryMap from "./summarymap";

import LossPercentComponent from "./loss_percent_component";
import ClusterMap from "./cluster_map";

const columns = [
  {
    title: "Priority",
    dataIndex: "priority",
    key: "priority",
  },
  {
    title: "Descritption",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Action",
    dataIndex: "",
    render: (_, record) => (
      <Button
        style={{
          boxShadow: "10px 10px 10px #d1d9e6,-10px -10px 10px #f9f9f9",
        }}
      >
        Adaptation Solutions
      </Button>
    ),
  },
];

let data = [];
let adaptation_data = [];

let options = [];
let Item = [];
let singledata = [];
let data01 = [];
let flat_data = [];

let HazardOptions = [
  /* { key: "River Flood", value: "River Flood", text: "River Flood" },
  { key: "Rainfall Flood", value: "Rainfall Flood", text: "Rainfall Flood" },

  { key: "Drought", value: "Drought", text: "Drought" },
  { key: "Landslide", value: "Landslide", text: "Landslide" },
  { key: "Storm Surge", value: "Storm Surge", text: "Storm Surge" },

  { key: "Extreme heat", value: "Extreme Heat", text: "Extreme heat" },
  { key: "Typhoon", value: "Typhoon", text: "Typhoon" },
  { key: "Snow Melt", value: "Snow Melt", text: "Snow Melt" },
  { key: "Sea Level Rise", value: "Sea Level Rise", text: "Sea Level Rise" },
  { key: "Wild Fire", value: "Wild Fire", text: "Wild Fire" },*/
];
const currencyOptions = [
  { key: "EUR", value: "EUR", text: "Euro" },
  { key: "USD", value: "USD", text: "US Dollars" },
  { key: "HKD", value: "HKD", text: "HongKong Dollars" },
  { key: "GBP", value: "GBP", text: "Pound" },
];
const MitigationOptions = [
  { key: "River Flood", value: "river_flood", text: "River Flood" },
  { key: "Rainfall Flood", value: "rainfall_flood", text: "Rainfall Flood" },
];
const MitigationMeasureOptions = [
  { key: "No", value: "no", text: "No" },
  { key: "Low", value: "low", text: "Low" },
  { key: "Medium", value: "medium", text: "Medium" },
  { key: "High", value: "high", text: "High" },
];

const ContinentOptions = [];
const FinanceOptions = [
  { key: "financial", value: true, text: "Financial" },
  { key: "percent", value: false, text: "Percentage" },
];
const ContentOptions = [
  { key: "add", value: true, text: "Add Content" },
  { key: "remove", value: false, text: "Remove Content" },
];
const GdpOptions = [
  {
    key: "Unadjusted Operational Loss",
    value: false,
    text: "Unadjusted Operational Loss",
  },
  {
    key: "GDP Adjusted Operational Loss",
    value: true,
    text: "Operational Loss Adjusted with Long-term Climate Change Impact*",
  },
];
const AnalysisOptions = [
  { key: "rcp", value: "rcp", text: "RCP" },
  { key: "ssp", value: "ssp", text: "SSP" },
];

const RcpOptions = [
  { key: 2.6, value: 2.6, text: "2.6" },
  { key: 4.5, value: 4.5, text: "4.5" },
  { key: 8.5, value: 8.5, text: "8.5" },
];
let VariableOptions = [];
let AssetOptions = [];
const YearOptions = [
  { key: 2030, value: 2030, text: 2030 },
  { key: 2050, value: 2050, text: 2050 },
];
const YearOptions_ssp = [
  { key: 2025, value: 2025, text: 2025 },

  { key: 2030, value: 2030, text: 2030 },
  { key: 2035, value: 2035, text: 2035 },
  { key: 2040, value: 2040, text: 2040 },
  { key: 2045, value: 2045, text: 2045 },
  { key: 2050, value: 2050, text: 2050 },
  { key: 2055, value: 2055, text: 2055 },
  { key: 2060, value: 2060, text: 2060 },
  { key: 2065, value: 2065, text: 2065 },
  { key: 2070, value: 2070, text: 2070 },
  { key: 2075, value: 2075, text: 2075 },
  { key: 2080, value: 2080, text: 2080 },
  { key: 2085, value: 2085, text: 2085 },
  { key: 2090, value: 2090, text: 2090 },
  { key: 2095, value: 2095, text: 2095 },
  { key: 2100, value: 2100, text: 2100 },
];
const basementOptions = [
  { key: "yes", value: "yes", text: "yes" },
  { key: "no", value: "no", text: "no" },
  { key: "unknown", value: "unknown", text: "unknown" },
];
const constructionOptions = [
  { key: "wood", value: "wood", text: "wood" },
  { key: "concrete", value: "concrete", text: "concrete" },
  { key: "masonry", value: "masonry", text: "masonry" },
  { key: "xs home", value: "xs home", text: "xs home" },
  { key: "light metal", value: "light metal", text: "light metal" },
  { key: "steel", value: "steel", text: "steel" },
  { key: "unknown", value: "unknown", text: "unknown" },
];
const storiesOptions = [
  { key: 1, value: 1, text: 1 },
  { key: 2, value: 2, text: 2 },
  { key: "3 or More", value: "3 or More", text: "3 or More" },
  { key: "unknown", valeu: "unknown", text: "unknown" },
];
const occupancyOptions = [
  { key: "res", value: "res", text: "res" },
  { key: "com", value: "com", text: "com" },
  { key: "ind", value: "ind", text: "ind" },
  { key: "unknown", value: "unknown", text: "unknown" },
];
const tableOptions = [
  { key: "Top 3", value: 3, text: "Top 3" },
  { key: "Top 5", value: 5, text: "Top 5" },
  { key: "Top 10", value: 10, text: "Top 10" },
];
const CountOptions = [
  { key: "Top 3", value: "Top 3", text: "Top 3" },
  { key: "Top 5", value: "Top 5", text: "Top 5" },
  { key: "All", value: "All", text: "All" },
];
let ParameterOptions = [
  { key: "valuation", value: "valuation", text: "valuation" },
  { key: "loss", value: "loss", text: "Overall Loss" },
];
let mitigationOptions = [
  { key: "yes", value: true, text: "yes" },
  { key: "no", value: false, text: "no" },
];
const valuationOptions = [
  {
    key: "replacement",
    value: "technical_insurance_premium",
    text: "Percentage over Technical Insurance Premium(Replacement Value)",
  },
  {
    key: "valuation",
    value: "asset_value",
    text: "Percentage over Asset Value",
  },
];
let PropertyOptions = [];

class Dashboard extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    activeIndex: 0,
    loading: false,
    lossopen: false,
    heatopen: false,
    mitigation: false,
    articleopen: false,
    percentopen: false,
    edit_permission: "",
    project: "",
    currency: "USD",
    variable: "River Flood",
    rcp: 8.5,
    year: 2050,
    analysis: "rcp",
    modalOpen: false,
    feedback: "",
    asset_table: "",
    table: 3,
    risk: "",
    mitigation_risk: "",
    single_asset: "",
    activeItemName: "",
    single_asset_overall: "",
    singel_asset_chart: "",
    single_asset_loss: "",
    single_asset_portfolio_exposure: "",
    single_asset_portfolio_exposure_historic: "",
    adjusted_gdp: false,
    single_asset_progress: "",
    single_asset_cone_chart: "",
    RcpData: "",
    YearData: "",
    basement: "yes",
    construction: "wood",
    stories: "1",
    occupancy: "res",
    per_sq_m_value: 40000,
    scatter: "",
    yearDetail: "",
    detailed: false,
    global: false,
    metigation: "",
    metigation_measure: "high",
    singleYearDetail: "",
    portfolio_losses: "",
    portfolio_losses_flat: "",
    overall: "",
    heatmap: "",
    losses: "",
    data: "",
    financial: true,
    mapVariable: "FL8570",
    yearDetail_single: "",
    assets: [],
    typhoon_map_data: "",
    mapAsset: "",
    table_condition: false,
    oldname: "",
    newname: "",
    area: "",
    type: "",
    valuation: "",
    count: "All",
    subscription: "",
    singlePercentage: "",
    parameter: "loss",
    Million: true,
    exposure_variable: "Total",
    hazard_map_data: "",
    hazard_map_data_with_continent: "",
    property_type: "NA",
    long: "",
    mitigation_info: false,
    lat: "",
    combined_data: "",
    hazard_continent: "",
    zoom: 14,
    continent: "asia",
    activeIndex: -1,
    mitigation_variable: "river_flood",
    percentage: "",
    variables: "",
    property_types: "NA",
    country_loss: "",
    countryloss: "",
    with_content: false,
    ssp: 8.5,
    defaultbuilding: "",
    riskOpen: false,
    worldmap: false,
    risk_2020: "",
    losses_2020: "",
    filterOpen: false,
    hazard: "",
    hazard_map: "Typhoon",
    floors: "",
    use_valuation: "asset_value",
    ownership: "",
    construction_cost: "",
    revenue: "",
    Cvar: false,
    FilterInfo: false,
    key_finding_data: "",
    priority: false,
    key_finding_1: false,
    key_finding_2: false,
    key_finding_3: false,
    key_finding_p: false,
    key_finding_t: false,
    card_finding_1: false,
    card_finding_2: false,
    card_finding_3: false,
    card_finding_4: false,
    card_finding_5: false,
    info_table: false,
    single_asset_hazard: "",
    single_asset_hazard_2020: "",
    portfolio_exposure: "",
    portfolio_exposure_historic: "",
  };

  handleKeyFindings_1 = () => {
    this.setState({
      key_finding_1: true,
    });
  };
  handleKeyFindings_2 = () => {
    this.setState({
      key_finding_2: true,
    });
  };
  handleKeyFindings_3 = () => {
    this.setState({
      key_finding_3: true,
    });
  };
  handleKeyFindings_p = () => {
    this.setState({
      key_finding_p: true,
    });
  };
  handleKeyFindings_t = () => {
    this.setState({
      key_finding_t: true,
    });
  };
  handleCardFindings_1 = () => {
    this.setState({
      card_finding_1: true,
    });
  };
  handleCardFindings_2 = () => {
    this.setState({
      card_finding_2: true,
    });
  };
  handleCardFindings_3 = () => {
    this.setState({
      card_finding_3: true,
    });
  };
  handleCardFindings_4 = () => {
    this.setState({
      card_finding_4: true,
    });
  };
  handleKeyFindings_4 = () => {
    this.setState({
      info_table: true,
    });
  };
  handleCardFindings_5 = () => {
    this.setState({
      card_finding_5: true,
    });
  };
  handleKeyFindings_5 = () => {
    this.setState({
      info_table: true,
    });
  };
  handlepriority = () => {
    this.setState({
      priority: true,
    });
  };
  MitigationInfo = () => {
    this.setState({
      mitigation_info: true,
    });
  };

  handleCardClose = () => {
    this.setState({
      card_finding_1: false,
      card_finding_2: false,
      card_finding_3: false,
      card_finding_4: false,
      card_finding_5: false,
      priority: false,
    });
  };

  handleKeyClose = () => {
    this.setState({
      key_finding_1: false,
      key_finding_2: false,
      key_finding_3: false,
      key_finding_p: false,
      key_finding_t: false,
      info_table: false,
    });
  };
  FilterInfo = () => {
    this.setState({
      FilterInfo: true,
    });
  };
  FilterInfoClose = () => {
    this.setState({
      FilterInfo: false,
    });
  };
  handleCvar = () => {
    this.setState({
      Cvar: true,
    });
  };
  handleLossOpen = () => {
    this.setState({
      lossopen: true,
    });
  };
  handleArticleOpen = () => {
    this.setState({
      articleopen: true,
    });
  };
  handlePercentOpen = () => {
    this.setState({
      percentopen: true,
    });
  };
  handleHeatOpen = () => {
    this.setState({
      heatopen: true,
    });
  };
  handleRiskOpen = () => {
    this.setState({
      riskOpen: true,
    });
  };
  RiskClose = () => {
    this.setState({
      riskOpen: false,
    });
  };
  handleFilterOpen = () => {
    this.setState({
      filterOpen: true,
    });
  };
  filterClose = () => {
    this.setState({
      filterOpen: false,
    });
  };
  lossClose = () => {
    this.setState({
      lossopen: false,
    });
  };
  percentClose = () => {
    this.setState({
      percentopen: false,
    });
  };
  heatClose = () => {
    this.setState({
      heatopen: false,
    });
  };
  articleClose = () => {
    this.setState({
      articleopen: false,
    });
  };
  CvarClose = () => {
    this.setState({
      Cvar: false,
    });
  };
  MitigationClose = () => {
    this.setState({
      mitigation_info: false,
    });
  };

  componentDidMount() {
    this.props.getAssets();
    this.props.getProjectList("physical_risk");

    let formdata = new FormData();
    if (
      this.props.location.state !== null &&
      this.props.location.state.project_name.project
    ) {
      formdata.append(
        "project_name",
        this.props.location.state.project_name.project
      );
      formdata.append(
        "project_id",
        this.props.location.state.project_name.project_id
      );
      formdata.append(
        "scenario",
        this.props.location.state.project_name.default_filters.scenario
      );
      formdata.append("variable", this.state.exposure_variable);
      formdata.append(
        "year",
        this.props.location.state.project_name.default_filters.year
      );
      formdata.append("currency", this.state.currency);
      formdata.append("count", this.state.count);
      formdata.append("percentage_type", this.state.use_valuation);

      formdata.append("mitigation", this.state.mitigation);
      formdata.append(
        "analysis_type",
        this.props.location.state.project_name.default_filters.analysis_type
      );
      formdata.append("parameter", this.state.parameter);
      formdata.append("with_content", this.state.with_content);
      formdata.append("adjusted_gdp", this.state.adjusted_gdp);
      formdata.append("property_type", this.state.property_type);

      this.setState(
        {
          project: this.props.location.state.project_name.project,
          rcp: this.props.location.state.project_name.default_filters.scenario,
          ssp: this.props.location.state.project_name.default_filters.scenario,
          analysis: this.props.location.state.project_name.default_filters
            .analysis_type,
          year: this.props.location.state.project_name.default_filters.year,
          variables: this.props.location.state.project_name.variables,
          property_types: this.props.location.state.project_name.property_types,
        },
        () => this.props.addDashboard(formdata)
      );
    } else if (
      this.props.location.state !== null &&
      this.props.location.state.project_name
    ) {
      formdata.append("project_name", this.props.location.state.project_name);
      formdata.append("project_id", this.props.location.state.project_id);
      formdata.append("scenario", this.props.location.state.rcp);
      formdata.append("percentage_type", this.state.use_valuation);
      formdata.append("currency", this.state.currency);
      formdata.append("variable", this.state.exposure_variable);
      formdata.append("year", this.props.location.state.year);
      formdata.append("mitigation", this.state.mitigation);
      formdata.append("count", this.state.count);
      formdata.append("analysis_type", this.props.location.state.scenario);
      formdata.append("parameter", this.state.parameter);
      formdata.append("adjusted_gdp", this.state.adjusted_gdp);
      formdata.append("with_content", this.state.with_content);
      formdata.append("property_type", this.state.property_type);

      this.setState(
        {
          project: this.props.location.state.project_name,
          variables: this.props.location.state.variables,
          rcp: this.props.location.state.rcp,
          ssp: this.props.location.state.rcp,
          analysis: this.props.location.state.scenario,
          year: this.props.location.state.year,
          property_types: this.props.location.state.property_type,
        },
        () => this.props.addDashboard(formdata)
      );
    } else if (this.props.location.state == null) {
      this.props.history.push({
        pathname: "/location",
      });
    }

    let formdata1 = new FormData();
    if (
      this.props.location.state !== null &&
      this.props.location.state.project_name.project
    ) {
      formdata1.append(
        "project",
        this.props.location.state.project_name.project
      );
      formdata1.append("rcp", this.state.rcp);
      formdata1.append("year", this.state.year);
      formdata1.append("count", this.state.count);
      formdata1.append("with_content", this.state.with_content);

      formdata1.append("variable", this.state.exposure_variable);

      this.props.getDetailByYear(formdata1);
    } else if (
      this.props.location.state !== null &&
      this.props.location.state.project_name
    ) {
      formdata1.append("project", this.props.location.state.project_name);
      formdata1.append("rcp", this.state.rcp);
      formdata1.append("count", this.state.count);
      formdata1.append("with_content", this.state.with_content);

      formdata1.append("variable", this.state.exposure_variable);
      formdata1.append("year", this.state.year);

      this.props.getDetailByYear(formdata1);
    } else if (this.props.location.state == null) {
      this.props.history.push({
        pathname: "/location",
      });
    }

    /*let formdata2 = new FormData();
    if (
      this.props.location.state !== null &&
      this.props.location.state.project_name.project
    ) {
      formdata2.append(
        "project_name",
        this.props.location.state.project_name.project
      );

      this.props.getSummary(formdata2);
    } else if (
      this.props.location.state !== null &&
      this.props.location.state.project_name
    ) {
      formdata2.append("project_name", this.props.location.state.project_name);

      this.props.getSummary(formdata2);
    } else if (this.props.location.state == null) {
      this.props.history.push({
        pathname: "/location",
      });
    }*/
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.feedback !== this.props.feedback &&
      this.state.analysis === "rcp"
    ) {
      this.setState(
        {
          subscription: this.props.feedback.tier_permissions,
          feedback: this.props.feedback.overall.overall_bar_chart,
          asset_table: this.props.feedback.overall.asset_table,
          //risk: this.props.feedback.overall.progress_bars,
          risk_2020: this.props.feedback.overall.hazard_values_2020,
          losses: this.props.feedback.overall.loss_bars_data,
          portfolio_exposure: this.props.feedback.overall
            .portfolio_exposure_percentage_loss,
          portfolio_exposure_historic: this.props.feedback.overall
            .portfolio_exposure_percentage_loss_historic,
          edit_permission: this.props.feedback.data_edit_permissions,
          metigation: this.props.feedback.overall.metigation_measures,
          losses_2020: this.props.feedback.overall.loss_bars_data_historic,
          single_asset: this.props.feedback.single_asset,
          hazard: this.props.feedback.overall.hazard_values,
          typhoon_map_data: this.props.feedback.overall.typhoon_map_data,
          overall: this.props.feedback.overall,
          combined_data: this.props.feedback.overall.combined_data,
          heatmap: this.props.feedback.overall.heatmap,
          lat: this.props.feedback.overall.asset_table[0].latitude,
          long: this.props.feedback.overall.asset_table[0].longitude,
          percentage: this.props.feedback.overall.year_chart_data.percentage,
          yearDetail: this.props.feedback.overall.year_chart_data.dollar,
          country_loss: this.props.feedback.overall.country_wise_loss_data,
          hazard_continent: this.props.feedback.overall.hazard_map_continents,

          hazard_map_data: this.props.feedback.overall.hazard_map_data,
          hazard_map_data_with_continent: this.props.feedback.overall
            .hazard_map_data_with_continent,
          key_finding_data: this.props.feedback.overall.key_finding_data,
        },
        () => {
          this.setState({ table_condition: true });
          localStorage.setItem("with_content", this.state.with_content);
        }
      );
    }
    if (prevProps.assets !== this.props.assets) {
      console.log("user assets", this.props.assets);
      this.setState({ assets: this.props.assets });
    }

    /*  if (
      prevProps.detailyear !== this.props.detailyear &&
      this.state.analysis === "rcp"
    ) {
      console.log("year api update", this.props.detailyear.values);
      this.setState({
        yearDetail_single: this.props.detailyear.values,
      });
    }*/
    if (
      prevProps.feedback !== this.props.feedback &&
      this.state.analysis === "ssp"
    ) {
      this.setState(
        {
          subscription: this.props.feedback.tier_permissions,

          feedback: this.props.feedback.overall.overall_bar_chart,
          asset_table: this.props.feedback.overall.asset_table,
          //risk: this.props.feedback.overall.progress_bars,
          risk_2020: this.props.feedback.overall.hazard_values_2020,
          losses: this.props.feedback.overall.loss_bars_data,
          portfolio_exposure: this.props.feedback.overall
            .portfolio_exposure_percentage_loss,
          portfolio_exposure_historic: this.props.feedback.overall
            .portfolio_exposure_percentage_loss_historic,

          losses_2020: this.props.feedback.overall.loss_bars_data_historic,
          single_asset: this.props.feedback.single_asset,
          hazard: this.props.feedback.overall.hazard_values,
          combined_data: this.props.feedback.overall.combined_data,
          edit_permission: this.props.feedback.data_edit_permissions,
          overall: this.props.feedback.overall,
          heatmap: this.props.feedback.overall.heatmap,
          lat: this.props.feedback.overall.asset_table[0].latitude,
          long: this.props.feedback.overall.asset_table[0].longitude,
          hazard_continent: this.props.feedback.overall.hazard_map_continents,
          country_loss: this.props.feedback.overall.country_wise_loss_data,
          hazard_map_data: this.props.feedback.overall.hazard_map_data,
          hazard_map_data_with_continent: this.props.feedback.overall
            .hazard_map_data_with_continent,
          key_finding_data: this.props.feedback.overall.key_finding_data,
          percentage: this.props.feedback.overall.year_chart_data.percentage,
          yearDetail: this.props.feedback.overall.year_chart_data.dollar,
        },
        () => {
          this.setState({ table_condition: true });
        }
      );
    }
  }

  Edit = (asset, newAsset) => {
    this.setState(
      {
        oldname: asset.name,
        newname: newAsset.name,
        area: newAsset.area,
        valuation: newAsset.valuation,
        type: newAsset.type.toLowerCase(),
        floors: newAsset.floors,
        ownership: newAsset.ownership,
        construction_cost: newAsset.construction_cost,
        revenue: newAsset.revenue,
        mitigation_risk: newAsset.mitigation_risk,
      },
      this.EditAsset
    );
  };
  EditAsset() {
    let formdata = new FormData();
    formdata.append("project_name", this.state.project);
    formdata.append("oldname", this.state.oldname);
    formdata.append("newname", this.state.newname);
    formdata.append("area", this.state.area);
    formdata.append("type", this.state.type);
    formdata.append("valuation", this.state.valuation);
    formdata.append("floors", this.state.floors);
    formdata.append("mitigation_risk", this.state.mitigation_risk);
    formdata.append("ownership", this.state.ownership);
    formdata.append("construction_cost", this.state.construction_cost);
    formdata.append("revenue", this.state.revenue);

    this.setState({ loading: true }, () => this.props.updateAsset(formdata));
  }

  handleProject = (e, { value }) => {
    this.setState({ project: value }, () => console.log(this.state.project));
  };

  handleRCP = (e, { value }) => {
    this.setState({ rcp: value }, () => console.log(this.state.rcp));
  };
  handleVariable = (e, { value }) => {
    this.setState({ mapVariable: value });
  };

  handleYear = (e, { value }) => {
    this.setState({ year: value }, () => console.log(this.state.year));
  };
  handleLogout = () => {
    this.props.logout();
  };
  handleOpen = (asset) => {
    let buildingData = this.state.asset_table.filter(
      (building) => building.name == asset
    );

    this.setState(
      {
        modalOpen: true,
        defaultbuilding: buildingData,
        activeItemName: asset,
      },
      this.handleSingleAsset
    );
  };

  handleClose = () => this.setState({ modalOpen: false });

  handleSingleAsset = () => {
    if (this.state.single_asset.length > 0) {
      Item = this.state.single_asset.filter(
        (asset) => asset.asset_name == this.state.activeItemName
      );
      this.setState({ single_asset_overall: Item }, this.handleSingleChart);
    }
    if (this.state.analysis == "rcp") {
      this.setState({
        singleYearDetail: Item[0].year_chart_data.dollar,
        singlePercentage: Item[0].year_chart_data.percentage,
        mapAsset: Item[0],
      });
    }
    if (this.state.analysis == "ssp") {
      this.setState({
        singleYearDetail: Item[0].year_chart_data.dollar,
        singlePercentage: Item[0].year_chart_data.percentage,
        mapAsset: Item[0],
      });
    }
  };
  handleSingleChart = () => {
    singledata = [
      {
        name: "2020",
        "RCP2.6": 0,
        "RCP4.5": 0,
        "RCP8.5": this.state.single_asset_overall[0].overall_bar_chart["2020"],
      },

      {
        name: "2030",
        "RCP2.6": this.state.single_asset_overall[0].overall_bar_chart[
          "2030_26"
        ],
        "RCP4.5": this.state.single_asset_overall[0].overall_bar_chart[
          "2030_45"
        ],
        "RCP8.5": this.state.single_asset_overall[0].overall_bar_chart[
          "2030_85"
        ],
      },

      {
        name: "2050",
        "RCP2.6": this.state.single_asset_overall[0].overall_bar_chart[
          "2050_26"
        ],
        "RCP4.5": this.state.single_asset_overall[0].overall_bar_chart[
          "2050_45"
        ],
        "RCP8.5": this.state.single_asset_overall[0].overall_bar_chart[
          "2050_85"
        ],
      },
    ];

    this.setState(
      {
        singel_asset_chart: singledata,
        single_asset_hazard: this.state.single_asset_overall[0].hazard_values,
        single_asset_hazard_2020: this.state.single_asset_overall[0]
          .hazard_values_2020,

        single_asset_loss: this.state.single_asset_overall[0].loss_bars_data,
        single_asset_loss_2020: this.state.single_asset_overall[0]
          .loss_bars_data_historic,
        single_asset_portfolio_exposure: this.state.single_asset_overall[0]
          .portfolio_exposure_percentage_loss,
        single_asset_portfolio_exposure_historic: this.state
          .single_asset_overall[0].portfolio_exposure_percentage_loss_historic,
        //single_asset_progress: this.state.single_asset_overall[0].progress_bars,
        //single_asset_progress_2020:
        //this.state.single_asset_overall[0].progress_bars_2020,
        single_asset_cone_chart: this.state.single_asset_overall[0].cone_chart,
      },
      this.handleComparison
    );
  };

  handleComparison = () => {
    let RcpData = [
      {
        name: 2.6,
        variable: "OVERALL",
        index: this.state.single_asset_cone_chart.cone1_26["OVERALL"],
      },

      {
        name: 2.6,
        variable: "River Flood",
        index: this.state.single_asset_cone_chart.cone1_26.both["River Flood"],
      },
      {
        name: 2.6,
        variable: "Drought",
        index: this.state.single_asset_cone_chart.cone1_26.both["Drought"],
      },

      {
        name: 2.6,
        variable: "Storm Surge",
        index: this.state.single_asset_cone_chart.cone1_26.both["Storm Surge"],
      },
      {
        name: 2.6,
        variable: "LandSlide",
        index: this.state.single_asset_cone_chart.cone1_26.both["Landslide"],
      },

      {
        name: 2.6,
        variable: "Extreme Heat",
        index: this.state.single_asset_cone_chart.cone1_26.both["Extreme heat"],
      },
      {
        name: 2.6,
        variable: "Rainfall Flood",
        index: this.state.single_asset_cone_chart.cone1_26.both[
          "Rainfall Flood"
        ],
      },
      {
        name: 2.6,
        variable: "SnowMelt",
        index: this.state.single_asset_cone_chart.cone1_26.both["Snow Melt"],
      },
      {
        name: 2.6,
        variable: "Sea Level Rise",
        index: this.state.single_asset_cone_chart.cone1_26.both[
          "Sea Level Rise"
        ],
      },
      {
        name: 2.6,
        variable: "Typhoon",
        index: this.state.single_asset_cone_chart.cone1_26.both["Typhoon"],
      },
      {
        name: 4.5,
        variable: "OVERALL",
        index: this.state.single_asset_cone_chart.cone1_45["OVERALL"],
      },

      {
        name: 4.5,
        variable: "River Flood",
        index: this.state.single_asset_cone_chart.cone1_45.both["River Flood"],
      },
      {
        name: 4.5,
        variable: "Drought",
        index: this.state.single_asset_cone_chart.cone1_45.both["Drought"],
      },

      {
        name: 4.5,
        variable: "Storm Surge",
        index: this.state.single_asset_cone_chart.cone1_45.both["Storm Surge"],
      },
      {
        name: 4.5,
        variable: "LandSlide",
        index: this.state.single_asset_cone_chart.cone1_45.both["Landslide"],
      },

      {
        name: 4.5,
        variable: "Extreme Heat",
        index: this.state.single_asset_cone_chart.cone1_45.both["Extreme heat"],
      },
      {
        name: 4.5,
        variable: "Rainfall Flood",
        index: this.state.single_asset_cone_chart.cone1_45.both[
          "Rainfall Flood"
        ],
      },
      {
        name: 4.5,
        variable: "SnowMelt",
        index: this.state.single_asset_cone_chart.cone1_45.both["Snow Melt"],
      },
      {
        name: 4.5,
        variable: "Sea Level Rise",
        index: this.state.single_asset_cone_chart.cone1_45.both[
          "Sea Level Rise"
        ],
      },
      {
        name: 4.5,
        variable: "Typhoon",
        index: this.state.single_asset_cone_chart.cone1_45.both["Typhoon"],
      },
      {
        name: 8.5,
        variable: "OVERALL",
        index: this.state.single_asset_cone_chart.cone1_85["OVERALL"],
      },

      {
        name: 8.5,
        variable: "River Flood",
        index: this.state.single_asset_cone_chart.cone1_85.both["River Flood"],
      },
      {
        name: 8.5,
        variable: "Drought",
        index: this.state.single_asset_cone_chart.cone1_85.both["Drought"],
      },

      {
        name: 8.5,
        variable: "Storm Surge",
        index: this.state.single_asset_cone_chart.cone1_85.both["Storm Surge"],
      },
      {
        name: 8.5,
        variable: "LandSlide",
        index: this.state.single_asset_cone_chart.cone1_85.both["Landslide"],
      },

      {
        name: 8.5,
        variable: "Extreme Heat",
        index: this.state.single_asset_cone_chart.cone1_85.both["Extreme heat"],
      },
      {
        name: 8.5,
        variable: "Rainfall Flood",
        index: this.state.single_asset_cone_chart.cone1_85.both[
          "Rainfall Flood"
        ],
      },
      {
        name: 8.5,
        variable: "SnowMelt",
        index: this.state.single_asset_cone_chart.cone1_85.both["Snow Melt"],
      },
      {
        name: 8.5,
        variable: "Sea Level Rise",
        index: this.state.single_asset_cone_chart.cone1_85.both[
          "Sea Level Rise"
        ],
      },
      {
        name: 8.5,
        variable: "Typhoon",
        index: this.state.single_asset_cone_chart.cone1_85.both["Typhoon"],
      },
    ];
    let max = this.state.single_asset_cone_chart.year_max;

    let RcpConfig = {
      data: RcpData,
      isGroup: true,
      yAxis: {
        max: max + 0.2 * max,
      },
      xField: "variable",
      yField: "index",
      seriesField: "name",
      colorField: "name",

      legend: false,
      label: {
        position: "top",
      },
      color: ["#2077b4", "#FE6702", "#4da02d"],
    };
    if (this.state.analysis == "rcp") {
      let yearData = [
        {
          name: 2020,
          variable: "OVERALL",
          index: this.state.single_asset_cone_chart.cone3_20["OVERALL"],
        },

        {
          name: 2020,
          variable: "River Flood",
          index: this.state.single_asset_cone_chart.cone3_20.both[
            "River Flood"
          ],
        },
        {
          name: 2020,
          variable: "Drought",
          index: this.state.single_asset_cone_chart.cone3_20.both["Drought"],
        },

        {
          name: 2020,
          variable: "Storm Surge",
          index: this.state.single_asset_cone_chart.cone3_20.both[
            "Storm Surge"
          ],
        },
        {
          name: 2020,
          variable: "LandSlide",
          index: this.state.single_asset_cone_chart.cone3_20.both["Landslide"],
        },

        {
          name: 2020,
          variable: "Extreme Heat",
          index: this.state.single_asset_cone_chart.cone3_20.both[
            "Extreme heat"
          ],
        },
        {
          name: 2020,
          variable: "Rainfall Flood",
          index: this.state.single_asset_cone_chart.cone3_20.both[
            "Rainfall Flood"
          ],
        },
        {
          name: 2020,
          variable: "SnowMelt",
          index: this.state.single_asset_cone_chart.cone3_20.both["Snow Melt"],
        },
        {
          name: 2020,
          variable: "Sea Level Rise",
          index: this.state.single_asset_cone_chart.cone3_20.both[
            "Sea Level Rise"
          ],
        },
        {
          name: 2020,
          variable: "Typhoon",
          index: this.state.single_asset_cone_chart.cone3_20.both["Typhoon"],
        },
        {
          name: 2030,
          variable: "OVERALL",
          index: this.state.single_asset_cone_chart.cone3_30["OVERALL"],
        },

        {
          name: 2030,
          variable: "River Flood",
          index: this.state.single_asset_cone_chart.cone3_30.both[
            "River Flood"
          ],
        },
        {
          name: 2030,
          variable: "Drought",
          index: this.state.single_asset_cone_chart.cone3_30.both["Drought"],
        },

        {
          name: 2030,
          variable: "Storm Surge",
          index: this.state.single_asset_cone_chart.cone3_30.both[
            "Storm Surge"
          ],
        },
        {
          name: 2030,
          variable: "LandSlide",
          index: this.state.single_asset_cone_chart.cone3_30.both["Landslide"],
        },

        {
          name: 2030,
          variable: "Extreme Heat",
          index: this.state.single_asset_cone_chart.cone3_30.both[
            "Extreme heat"
          ],
        },
        {
          name: 2030,
          variable: "Rainfall Flood",
          index: this.state.single_asset_cone_chart.cone3_30.both[
            "Rainfall Flood"
          ],
        },
        {
          name: 2030,
          variable: "SnowMelt",
          index: this.state.single_asset_cone_chart.cone3_30.both["Snow Melt"],
        },
        {
          name: 2030,
          variable: "Sea Level Rise",
          index: this.state.single_asset_cone_chart.cone3_30.both[
            "Sea Level Rise"
          ],
        },
        {
          name: 2030,
          variable: "Typhoon",
          index: this.state.single_asset_cone_chart.cone3_30.both["Typhoon"],
        },
        {
          name: 2050,
          variable: "OVERALL",
          index: this.state.single_asset_cone_chart.cone3_50["OVERALL"],
        },

        {
          name: 2050,
          variable: "River Flood",
          index: this.state.single_asset_cone_chart.cone3_50.both[
            "River Flood"
          ],
        },
        {
          name: 2050,
          variable: "Drought",
          index: this.state.single_asset_cone_chart.cone3_50.both["Drought"],
        },

        {
          name: 2050,
          variable: "Storm Surge",
          index: this.state.single_asset_cone_chart.cone3_50.both[
            "Storm Surge"
          ],
        },
        {
          name: 2050,
          variable: "LandSlide",
          index: this.state.single_asset_cone_chart.cone3_50.both["Landslide"],
        },

        {
          name: 2050,
          variable: "Extreme Heat",
          index: this.state.single_asset_cone_chart.cone3_50.both[
            "Extreme heat"
          ],
        },
        {
          name: 2050,
          variable: "Rainfall Flood",
          index: this.state.single_asset_cone_chart.cone3_50.both[
            "Rainfall Flood"
          ],
        },
        {
          name: 2050,
          variable: "SnowMelt",
          index: this.state.single_asset_cone_chart.cone3_50.both["Snow Melt"],
        },
        {
          name: 2050,
          variable: "Sea Level Rise",
          index: this.state.single_asset_cone_chart.cone3_50.both[
            "Sea Level Rise"
          ],
        },
        {
          name: 2050,
          variable: "Typhoon",
          index: this.state.single_asset_cone_chart.cone3_50.both["Typhoon"],
        },
      ];
      let max = this.state.single_asset_cone_chart.scenario_max;

      let yearConfig = {
        data: yearData,
        color: ["#f47a7b", "#8cb0f8", "#18202F"],
        yAxis: {
          max: max + 0.2 * max,
        },
        legend: false,
        isGroup: true,
        xField: "variable",
        colorField: "name",

        yField: "index",
        seriesField: "name",

        label: {
          position: "top",
        },
      };
      this.setState({ YearData: yearConfig });
    } else if (this.state.analysis == "ssp") {
      let yearData = [
        {
          name: 2020,
          variable: "OVERALL",
          index: this.state.single_asset_cone_chart.cone3_20["OVERALL"],
        },

        {
          name: 2020,
          variable: "River Flood",
          index: this.state.single_asset_cone_chart.cone3_20.both[
            "River Flood"
          ],
        },
        {
          name: 2020,
          variable: "Drought",
          index: this.state.single_asset_cone_chart.cone3_20.both["Drought"],
        },

        {
          name: 2020,
          variable: "Storm Surge",
          index: this.state.single_asset_cone_chart.cone3_20.both[
            "Storm Surge"
          ],
        },
        {
          name: 2020,
          variable: "LandSlide",
          index: this.state.single_asset_cone_chart.cone3_20.both["Landslide"],
        },

        {
          name: 2020,
          variable: "Extreme Heat",
          index: this.state.single_asset_cone_chart.cone3_20.both[
            "Extreme heat"
          ],
        },
        {
          name: 2020,
          variable: "Rainfall Flood",
          index: this.state.single_asset_cone_chart.cone3_20.both[
            "Rainfall Flood"
          ],
        },
        {
          name: 2020,
          variable: "SnowMelt",
          index: this.state.single_asset_cone_chart.cone3_20.both["Snow Melt"],
        },
        {
          name: 2020,
          variable: "Sea Level Rise",
          index: this.state.single_asset_cone_chart.cone3_20.both[
            "Sea Level Rise"
          ],
        },
        {
          name: 2020,
          variable: "Typhoon",
          index: this.state.single_asset_cone_chart.cone3_20.both["Typhoon"],
        },
        {
          name: 2030,
          variable: "OVERALL",
          index: this.state.single_asset_cone_chart.cone3_30["OVERALL"],
        },

        {
          name: 2030,
          variable: "River Flood",
          index: this.state.single_asset_cone_chart.cone3_30.both[
            "River Flood"
          ],
        },
        {
          name: 2030,
          variable: "Drought",
          index: this.state.single_asset_cone_chart.cone3_30.both["Drought"],
        },

        {
          name: 2030,
          variable: "Storm Surge",
          index: this.state.single_asset_cone_chart.cone3_30.both[
            "Storm Surge"
          ],
        },
        {
          name: 2030,
          variable: "LandSlide",
          index: this.state.single_asset_cone_chart.cone3_30.both["Landslide"],
        },

        {
          name: 2030,
          variable: "Extreme Heat",
          index: this.state.single_asset_cone_chart.cone3_30.both[
            "Extreme heat"
          ],
        },
        {
          name: 2030,
          variable: "Rainfall Flood",
          index: this.state.single_asset_cone_chart.cone3_30.both[
            "Rainfall Flood"
          ],
        },
        {
          name: 2030,
          variable: "SnowMelt",
          index: this.state.single_asset_cone_chart.cone3_30.both["Snow Melt"],
        },
        {
          name: 2030,
          variable: "Sea Level Rise",
          index: this.state.single_asset_cone_chart.cone3_30.both[
            "Sea Level Rise"
          ],
        },
        {
          name: 2030,
          variable: "Typhoon",
          index: this.state.single_asset_cone_chart.cone3_30.both["Typhoon"],
        },
        {
          name: 2050,
          variable: "OVERALL",
          index: this.state.single_asset_cone_chart.cone3_50["OVERALL"],
        },

        {
          name: 2050,
          variable: "River Flood",
          index: this.state.single_asset_cone_chart.cone3_50.both[
            "River Flood"
          ],
        },
        {
          name: 2050,
          variable: "Drought",
          index: this.state.single_asset_cone_chart.cone3_50.both["Drought"],
        },

        {
          name: 2050,
          variable: "Storm Surge",
          index: this.state.single_asset_cone_chart.cone3_50.both[
            "Storm Surge"
          ],
        },
        {
          name: 2050,
          variable: "LandSlide",
          index: this.state.single_asset_cone_chart.cone3_50.both["Landslide"],
        },

        {
          name: 2050,
          variable: "Extreme Heat",
          index: this.state.single_asset_cone_chart.cone3_50.both[
            "Extreme heat"
          ],
        },
        {
          name: 2050,
          variable: "Rainfall Flood",
          index: this.state.single_asset_cone_chart.cone3_50.both[
            "Rainfall Flood"
          ],
        },
        {
          name: 2050,
          variable: "SnowMelt",
          index: this.state.single_asset_cone_chart.cone3_50.both["Snow Melt"],
        },
        {
          name: 2050,
          variable: "Sea Level Rise",
          index: this.state.single_asset_cone_chart.cone3_50.both[
            "Sea Level Rise"
          ],
        },
        {
          name: 2050,
          variable: "Typhoon",
          index: this.state.single_asset_cone_chart.cone3_50.both["Typhoon"],
        },
        {
          name: 2100,
          variable: "OVERALL",
          index: this.state.single_asset_cone_chart.cone3_100.both["OVERALL"],
        },

        {
          name: 2100,
          variable: "River Flood",
          index: this.state.single_asset_cone_chart.cone3_100.both[
            "River Flood"
          ],
        },
        {
          name: 2100,
          variable: "Drought",
          index: this.state.single_asset_cone_chart.cone3_100.both["Drought"],
        },

        {
          name: 2100,
          variable: "Storm Surge",
          index: this.state.single_asset_cone_chart.cone3_100.both[
            "Storm Surge"
          ],
        },
        {
          name: 2100,
          variable: "LandSlide",
          index: this.state.single_asset_cone_chart.cone3_100.both["Landslide"],
        },

        {
          name: 2100,
          variable: "Extreme Heat",
          index: this.state.single_asset_cone_chart.cone3_100.both[
            "Extreme heat"
          ],
        },
        {
          name: 2100,
          variable: "Rainfall Flood",
          index: this.state.single_asset_cone_chart.cone3_100.both[
            "Rainfall Flood"
          ],
        },
        {
          name: 2100,
          variable: "SnowMelt",
          index: this.state.single_asset_cone_chart.cone3_100.both["Snow Melt"],
        },
        {
          name: 2100,
          variable: "Sea Level Rise",
          index: this.state.single_asset_cone_chart.cone3_100.both[
            "Sea Level Rise"
          ],
        },
        {
          name: 2100,
          variable: "Typhoon",
          index: this.state.single_asset_cone_chart.cone3_100.both["Typhoon"],
        },
      ];
      let max = this.state.single_asset_cone_chart.scenario_max;

      let yearConfig = {
        data: yearData,
        color: ["#f47a7b", "#8CB0F8", "#18202F", "#256EFF"],
        yAxis: {
          max: max + 0.2 * max,
        },
        legend: false,
        isGroup: true,
        xField: "variable",
        colorField: "name",

        yField: "index",
        seriesField: "name",

        label: {
          position: "top",
        },
      };
      this.setState({ YearData: yearConfig });
    }

    this.setState(
      {
        RcpData: RcpConfig,
      },
      () => console.log("yeardata", this.state.yearData)
    );
    if (this.state.single_asset_hazard.both) {
      let ad_var = [
        "Rainfall Flood",
        "Extreme Heat",
        "River Flood",
        "Typhoon",
        "Storm Surge",
        "Landslide",
        "Drought",
        "Sea Level Rise",
        "Snow Melt",
        "Wild Fire",
      ];
      for (ad_var in this.state.single_asset_hazard.both) {
        if (this.state.single_asset_hazard.both[ad_var][2] > 50) {
          let value = this.state.single_asset_hazard.both[ad_var][2];

          adaptation_data.push({
            priority: value,
            description: ad_var,
          });
          console.log("adapt", adaptation_data);
        }
      }
    }
  };
  handleChange = (value, key) => {
    this.setState({
      [key]: value,
    });
  };
  handleChangePro = (value, key) => {
    this.setState(
      {
        [key]: value,
      },
      () => console.log("analysis", this.state.analysis)
    );
  };
  handleTable = (value, key) => {
    this.setState({
      [key]: value,
    });
  };
  handleExposure = (value, key) => {
    this.setState(
      {
        [key]: value,
      },
      this.handleSubmitPro
    );
  };
  handleSingleExposure = (value, key) => {
    this.setState(
      {
        [key]: value,
      },
      this.handleSubmitPro
    );
  };

  handleFullScreen = () => {
    document.getElementById("HeatMap").requestFullscreen();
  };
  handleLossFullScreen = () => {
    document.getElementById("Loss").requestFullscreen();
  };
  handleIntFullScreen = () => {
    document.getElementById("Intensity").requestFullscreen();
  };

  handleClickOpen = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleSubmitPro = () => {
    if (this.state.analysis == "rcp") {
      let formdata = new FormData();

      if (this.state.year == 2100) {
        this.state.year = 2050;

        formdata.append("project_name", this.state.project);
        formdata.append("scenario", this.state.rcp);
        formdata.append("variable", this.state.exposure_variable);
        formdata.append("year", this.state.year);
        formdata.append("currency", this.state.currency);
        //formdata.append("use_valuation", this.state.use_valuation);
        //formdata.append("currency", this.state.currency);
        formdata.append("percentage_type", this.state.use_valuation);
        formdata.append("mitigation", this.state.mitigation);
        formdata.append("count", this.state.count);
        formdata.append("analysis_type", this.state.analysis);
        formdata.append("parameter", this.state.parameter);
        formdata.append("with_content", this.state.with_content);
        formdata.append("adjusted_gdp", this.state.adjusted_gdp);
        formdata.append("property_type", this.state.property_type);
      } else {
        formdata.append("project_name", this.state.project);
        formdata.append("scenario", this.state.rcp);
        formdata.append("variable", this.state.exposure_variable);
        // formdata.append("currency", this.state.currency);
        formdata.append("year", this.state.year);
        // formdata.append("use_valuation", this.state.use_valuation);
        formdata.append("percentage_type", this.state.use_valuation);
        formdata.append("mitigation", this.state.mitigation);
        formdata.append("count", this.state.count);
        formdata.append("analysis_type", this.state.analysis);
        formdata.append("adjusted_gdp", this.state.adjusted_gdp);
        formdata.append("parameter", this.state.parameter);
        formdata.append("with_content", this.state.with_content);
        formdata.append("property_type", this.state.property_type);
      }

      let project = this.props.project.data.filter(
        (project) => project.name == this.state.project
      );
      console.log("project_submit", project[0].variables);

      this.props.addDashboard(formdata);

      this.setState({
        filterOpen: false,
        Million: true,

        variables: project[0].variables,
      });
    } else if (this.state.analysis == "ssp") {
      let formdata = new FormData();
      formdata.append("project_name", this.state.project);
      formdata.append("scenario", this.state.ssp);
      formdata.append("percentage_type", this.state.use_valuation);
      formdata.append("variable", this.state.exposure_variable);
      formdata.append("year", this.state.year);
      formdata.append("currency", this.state.currency);
      formdata.append("mitigation", this.state.mitigation);
      formdata.append("count", this.state.count);
      formdata.append("analysis_type", this.state.analysis);
      formdata.append("parameter", this.state.parameter);
      formdata.append("adjusted_gdp", this.state.adjusted_gdp);
      formdata.append("with_content", this.state.with_content);
      formdata.append("property_type", this.state.property_type);
      this.props.addDashboard(formdata);
      this.setState({
        filterOpen: false,
      });
    }
  };

  handleLocal = (e) => {
    this.setState(
      {
        localactive: true,
        globalactive: false,
      },
      this.handleSubmitPro
    );
  };

  handleGlobal = (e) => {
    this.setState(
      {
        globalactive: true,
        localactive: false,
      },
      this.handleSubmitPro
    );
  };
  handleFinance = (e) => {
    this.setState({
      financial: !this.state.financial,
    });
  };

  handleSsp = (e) => {
    this.setState({
      ssp: !this.state.ssp,
    });
  };

  handleWorld = (e) => {
    this.setState((prevState) => ({
      worldmap: !prevState.worldmap,
    }));
  };
  handleDetail = (e) => {
    this.setState((prevState) => ({
      detailed: !prevState.detailed,
    }));
  };
  handleContent = (e) => {
    localStorage.setItem("with_content", !this.state.with_content);
    this.setState({
      with_content: !this.state.with_content,
    });
  };
  handleAsset = (e, { value }) => {
    let selectedAsset = this.state.asset_table.filter(
      (asset) => asset.name == value
    );
    console.log("selectedAsset", selectedAsset[0].latitude);
    this.setState(
      {
        lat: selectedAsset[0].latitude,
        long: selectedAsset[0].longitude,
      },
      () => console.log("lat passing", this.state.lat, this.state.long)
    );
  };
  handleHazard = (e, { value }) => {
    this.setState(
      {
        hazard_map: value,
      },
      () => console.log("hazard", this.state.hazard_map)
    );
  };
  handleMitigation = (e, { value }) => {
    this.setState({
      mitigation_variable: value,
    });
  };
  handleMitigationMeasure = (e, { value }) => {
    this.setState({
      metigation_measure: value,
    });
  };

  useValuation = (e) => {
    this.setState(
      { use_valuation: !this.state.use_valuation },
      this.handleSubmitPro
    );
  };

  handleContinent = (e, { value }) => {
    console.log("Continents", value);
    this.setState({
      continent: value,
    });
  };

  handleClick = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ active: !prevState.active }));
  };

  handleAccClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex }, () =>
      console.log("active", this.state.activeIndex)
    );
  };
  componentWillUnmount() {
    if (this.view) {
      this.view.destroy();
    }
  }

  render() {
    console.log("map data dashboard", this.state.combined_data);
    let newVar = localStorage.getItem("variables") === this.state.variables;
    const isDarkMode = localStorage.getItem("theme") == "dark";
    let Var = this.state.variables;
    let Property = this.state.property_types;

    if (this.state.hazard_continent) {
      for (let i = 0; i < this.state.hazard_continent.length; i++) {
        ContinentOptions.push({
          key: this.state.hazard_continent[i],
          value: this.state.hazard_continent[i],
          text: this.state.hazard_continent[i],
        });
      }
    }
    console.log("subs", this.state.subscription);
    if (this.state.variables) {
      HazardOptions = [];

      for (let i = 0; i < this.state.variables.length; i++) {
        HazardOptions.push({
          key: this.state.variables[i],
          text: this.state.variables[i],
          value: this.state.variables[i],
        });
      }
    }

    /* if (this.state.variables.includes("Rainfall Flood")) {
      VariableOptions = [
        { key: "Total", value: "Total", text: "Total" },

        { key: "Typhoon", value: "Typhoon", text: "Typhoon" },
        { key: "Storm", value: "Storm Surge", text: "Storm Surge" },
        { key: "River Flood", value: "River Flood", text: "River Flood" },
      ];
      VariableOptions.push({
        key: "Rainfall Flood",
        value: "Rainfall Flood",
        text: "Rainfall Flood",
      });
    }
    if (!this.state.variables.includes("Rainfall Flood")) {
      VariableOptions = [
        { key: "Total", value: "Total", text: "Total" },

        { key: "Typhoon", value: "Typhoon", text: "Typhoon" },
        { key: "Storm", value: "Storm Surge", text: "Storm Surge" },
        { key: "River Flood", value: "River Flood", text: "River Flood" },
      ];
    }
    if (this.state.variables.includes("Extreme heat")) {
      VariableOptions = [
        { key: "Total", value: "Total", text: "Total" },

        { key: "Typhoon", value: "Typhoon", text: "Typhoon" },
        { key: "Storm", value: "Storm Surge", text: "Storm Surge" },
        { key: "River Flood", value: "River Flood", text: "River Flood" },
      ];
      VariableOptions.push({
        key: "Extreme Heat",
        value: "Extreme heat",
        text: "Extreme Heat",
      });
    }
    if (!this.state.variables.includes("Extreme heat")) {
      VariableOptions = [
        { key: "Total", value: "Total", text: "Total" },

        { key: "Typhoon", value: "Typhoon", text: "Typhoon" },
        { key: "Storm", value: "Storm Surge", text: "Storm Surge" },
        { key: "River Flood", value: "River Flood", text: "River Flood" },
      ];
    }
    if (
      this.state.variables.includes("Extreme heat") &&
      this.state.variables.includes("Rainfall Flood")
    ) {
      VariableOptions = [
        { key: "Total", value: "Total", text: "Total" },

        { key: "Typhoon", value: "Typhoon", text: "Typhoon" },
        { key: "Storm", value: "Storm Surge", text: "Storm Surge" },
        { key: "River Flood", value: "River Flood", text: "River Flood" },
      ];
      VariableOptions.push(
        {
          key: "Extreme Heat",
          value: "Extreme heat",
          text: "Extreme Heat",
        },
        {
          key: "Rainfall Flood",
          value: "Rainfall Flood",
          text: "Rainfall Flood",
        }
      );
    }*/

    if (Property != "NA") {
      console.log("Property value", this.state.property_types);
      PropertyOptions = [];
      for (let i = 0; i < Property.length; i++) {
        PropertyOptions.push({
          key: Property[i],
          value: Property[i],
          text: Property[i],
        });
      }
      ParameterOptions = [];
      ParameterOptions.push(
        { key: "valuation", value: "valuation", text: "valuation" },
        { key: "loss", value: "loss", text: "Overall Loss" },
        {
          key: "revenue",
          value: "revenue",
          text: "revenue",
        }
      );
    }
    if (this.state.asset_table) {
      AssetOptions = [];
      for (let i = 0; i < this.state.asset_table.length; i++) {
        AssetOptions.push({
          key: this.state.asset_table[i].name,
          value: this.state.asset_table[i].name,
          text: this.state.asset_table[i].name.slice(0, 10),
        });
      }
    }

    const {
      continent,
      value,
      basement,
      construction,
      stories,
      occupancy,
      project,
      rcp,
      year,
      active,
      table,
      count,
      parameter,
      exposure_variable,
      property_type,
      ssp,
      analysis,
    } = this.state;

    let total_asset_value = 0;

    const { activeIndex } = this.state;

    if (this.props.project.data) {
      console.log("project", this.props.project.data);
      const projects = this.props.project.data;

      options = [];
      for (let i = 0; i < projects.length; i++) {
        options.push({
          key: projects[i].name,
          value: projects[i].name,
          text: projects[i].name.slice(10),
        });
      }
    }

    if (this.props.isLoading) {
      return <div id="loading"> </div>;
    }

    return (
      <div>
        <Container
          fluid
          style={{ backgroundColor: "#18202F" }}
          className="sticky-top"
        >
          <Row>
            <br />
            <Breadcrumb>
              <Breadcrumb.Item href="/project">
                {this.state.project}
              </Breadcrumb.Item>
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Container>
        <br />
        <Icon
          name="info"
          onClick={this.FilterInfo}
          style={{ cursor: "pointer", float: "right", marginRight: "2.75%" }}
        />{" "}
        <Button
          style={{
            backgroundColor: "rgb(255,171,0)",
            color: "black",
            float: "right",
          }}
          onClick={this.handleFilterOpen}
        >
          Advanced Filter{" "}
        </Button>
        <br />
        <br />
        <Container fluid={true} style={{ marginLeft: "2%" }}>
          {/* <Row>
            <Col xs={12} sm={12} lg={10}></Col>
            <Col lg={2}>
              <p style={{ whiteSpace: "nowrap" }}>
                {" "}
                <Checkbox
                  toggle
                  onClick={() => this.useValuation()}
                  checked={this.state.use_valuation}
                  label="Use Valuation"
                />
              </p>
            </Col>
    </Row>*/}

          <>
            <br />

            {this.state.subscription.overall_dashboard &&
            this.state.subscription.overall_dashboard.overview_card ? (
              <Row>
                <Col className="firstshadow" lg={7} xs={12} sm={12}>
                  <br />
                  <Row>
                    <Col lg={5} className="borderleft borderleft_1">
                      <Row>
                        <Col lg={5}>
                          <img
                            src={tr1}
                            style={{
                              height: "50px",
                            }}
                          />
                        </Col>
                        <Col lg={7}>
                          {this.state.overall ? (
                            <p className="trpara2">
                              <Row>
                                Project Name
                                <br />
                              </Row>
                              <br />

                              <Row style={{ wordBreak: "break-word" }}>
                                {this.state.project.slice(10)}
                              </Row>
                            </p>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={2}></Col>
                    <Col lg={5} className="borderleft borderleft_2">
                      <Row>
                        <Col lg={5}>
                          <img
                            src={tr2}
                            style={{
                              height: "50px",
                            }}
                          />
                        </Col>
                        <Col lg={7}>
                          {/*<Icon
                                    style={{
                                      float: "right",
                                      cursor: "pointer",
                                    }}
                                    name="info"
                                    onClick={this.handleCardFindings_2}
                                  />*/}
                          {this.state.losses ? (
                            <p className="trpara2">
                              <Row> Date of Analysis</Row>
                              <br />
                              {this.props.feedback.created_at ? (
                                <Row>
                                  {this.props.feedback.created_at.slice(0, 10)}
                                </Row>
                              ) : null}
                            </p>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <br />
                  <br />

                  <Row>
                    <Col lg={5} className="borderleft borderleft_3 mx-auto">
                      <Row>
                        <Col lg={5}>
                          <img
                            src={tr3}
                            style={{
                              height: "50px",
                            }}
                          />
                        </Col>
                        <Col lg={7}>
                          <p className="trpara2">
                            <Row> Asset Count </Row>
                            <br />
                            <Row>{this.state.asset_table.length}</Row>
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={2}></Col>
                    <Col lg={5} className="borderleft  borderleft_4 mx-auto">
                      <Row>
                        <Col lg={5}>
                          <img
                            src={tr4}
                            style={{
                              height: "50px",
                            }}
                          />
                        </Col>
                        <Col lg={7}>
                          {/* <Icon
                                    style={{
                                      float: "right",
                                      cursor: "pointer",
                                      padding: "1%",
                                    }}
                                    name="info"
                                    onClick={this.handleCardFindings_4}
                                  />*/}
                          {this.state.overall ? (
                            <p className="trpara2">
                              <Row> Hazard Count</Row>
                              <br />
                              <Row>{this.state.variables.length}</Row>
                            </p>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col
                  className="firstshadowmap"
                  lg={4}
                  style={{
                    border: "1px solid #E6E6E6",
                  }}
                >
                  <Row className="mainheading">
                    <p
                      style={{
                        width: "100%",
                        fontSize: "20px",
                      }}
                    >
                      {this.state.analysis == "rcp" ? (
                        <span
                          className={"dark-mode-theme-color-1"}
                          style={{
                            fontWeight: "600",
                            color: "black",
                          }}
                        >
                          Physical Climate Risk for RCP&nbsp;
                          {this.state.rcp} {this.state.year}
                        </span>
                      ) : null}
                      {this.state.analysis == "ssp" ? (
                        <span
                          className={"dark-mode-theme-color-1"}
                          style={{
                            fontWeight: "600",
                            color: "black",
                          }}
                        >
                          Physical Climate Risk for SSP&nbsp;
                          {this.state.ssp} {this.state.year}
                        </span>
                      ) : null}
                    </p>
                  </Row>

                  <Row style={{ padding: "2%" }}>
                    <Col lg={6}>
                      <Row>
                        {this.state.exposure_variable == "Total" &&
                        this.state.losses["detailed"] ? (
                          <p
                            style={{
                              fontSize: "1.2rem",
                              color: "black",
                            }}
                          >
                            {" "}
                            Climate Value at Risk{" "}
                            <Icon
                              style={{
                                float: "right",
                                cursor: "pointer",
                              }}
                              name="info"
                              onClick={this.handleCardFindings_3}
                            />
                            {this.state.losses["total"]["Total Loss"][0] <
                            1000 ? (
                              <p
                                style={{
                                  fontSize: "1rem",
                                  color: "black",
                                }}
                              >
                                $
                                {this.state.losses["total"][
                                  "Total Loss"
                                ][0].toFixed(2)}
                                &nbsp;Million
                              </p>
                            ) : (
                              <p
                                style={{
                                  fontSize: "1rem",
                                  color: "black",
                                }}
                              >
                                $
                                {(
                                  this.state.losses["total"]["Total Loss"][0] /
                                  1000
                                ).toFixed(2)}
                                &nbsp;Billion
                              </p>
                            )}{" "}
                          </p>
                        ) : null}
                      </Row>
                      <br />
                      <Row>
                        <p
                          style={{
                            fontSize: "1.2rem",
                            color: "black",
                          }}
                        >
                          {" "}
                          Maximum Exposure
                          <Icon
                            style={{
                              float: "right",
                              cursor: "pointer",
                              padding: "1%",
                            }}
                            name="info"
                            onClick={this.handleCardFindings_1}
                          />
                          <br />
                          {this.state.overall["Max_exposure"] < 1000 ? (
                            <p
                              style={{
                                fontSize: "1rem",
                                color: "black",
                              }}
                            >
                              ${this.state.overall["Max_exposure"].toFixed(2)}{" "}
                              Million
                            </p>
                          ) : (
                            <p>
                              $
                              {(
                                this.state.overall["Max_exposure"] / 1000
                              ).toFixed(2)}{" "}
                              Billion
                            </p>
                          )}{" "}
                        </p>
                        {this.state.losses["detailed"] &&
                        this.state.exposure_variable != "Total" &&
                        this.state.exposure_variable != "Extreme heat" ? (
                          <Row>
                            <Col
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "1rem",
                                  textTransform: "capitalize",
                                }}
                              >
                                {" "}
                                {this.state.exposure_variable}
                                Exposure
                                <br />
                                {this.state.overall[
                                  `${this.state.exposure_variable}_exposure`
                                ] < 1000 ? (
                                  <p>
                                    {" "}
                                    ${" "}
                                    {this.state.overall[
                                      `${this.state.exposure_variable}_exposure`
                                    ].toFixed(2)}
                                    Million{" "}
                                  </p>
                                ) : (
                                  <p>
                                    ${" "}
                                    {(
                                      this.state.overall[
                                        `${this.state.exposure_variable}_exposure`
                                      ] / 1000
                                    ).toFixed(2)}
                                    Billion{" "}
                                  </p>
                                )}{" "}
                              </p>

                              <Tooltip
                                title={`Operational Loss: $ ${this.state.losses[
                                  "detailed"
                                ][
                                  `Operational ${this.state.exposure_variable} Loss`
                                ][0].toFixed(2)} Mn
                    Damage Loss $ ${this.state.losses["detailed"][
                      `Asset ${this.state.exposure_variable} Damage`
                    ][0].toFixed(2)} Mn`}
                              >
                                <p
                                  style={{
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                    textTransform: "capitalize",
                                    marginBottom: "0em",
                                  }}
                                >
                                  {this.state.exposure_variable} Value at Risk
                                </p>

                                {parseFloat(
                                  this.state.losses["detailed"][
                                    `Asset ${this.state.exposure_variable} Damage`
                                  ]
                                ) +
                                  parseFloat(
                                    this.state.losses["detailed"][
                                      `Operational ${this.state.exposure_variable} Loss`
                                    ]
                                  ) <
                                1000 ? (
                                  <p>
                                    {" "}
                                    ${" "}
                                    {(
                                      parseFloat(
                                        this.state.losses["detailed"][
                                          `Asset ${this.state.exposure_variable} Damage`
                                        ]
                                      ) +
                                      parseFloat(
                                        this.state.losses["detailed"][
                                          `Operational ${this.state.exposure_variable} Loss`
                                        ]
                                      )
                                    ).toFixed(2)}{" "}
                                    Million
                                  </p>
                                ) : (
                                  <p>
                                    ${" "}
                                    {(
                                      (parseFloat(
                                        this.state.losses["detailed"][
                                          `Asset ${this.state.exposure_variable} Damage`
                                        ]
                                      ) +
                                        parseFloat(
                                          this.state.losses["detailed"][
                                            `Operational ${this.state.exposure_variable} Loss`
                                          ]
                                        )) /
                                      1000
                                    ).toFixed(2)}{" "}
                                    Billion
                                  </p>
                                )}
                              </Tooltip>
                            </Col>
                          </Row>
                        ) : null}
                        {this.state.losses["detailed"] &&
                        this.state.exposure_variable == "Extreme heat" ? (
                          <Row>
                            <Col
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "1rem",
                                  textAlign: "center",
                                  textTransform: "capitalize",
                                }}
                              >
                                {this.state.exposure_variable} Exposure
                                <br />
                                {this.state.overall[
                                  `${this.state.exposure_variable}_exposure`
                                ] < 1000 ? (
                                  <p>
                                    ${" "}
                                    {this.state.overall[
                                      `${this.state.exposure_variable}_exposure`
                                    ].toFixed(2)}{" "}
                                    Million
                                  </p>
                                ) : (
                                  <p>
                                    ${" "}
                                    {(
                                      this.state.overall[
                                        `${this.state.exposure_variable}_exposure`
                                      ] / 1000
                                    ).toFixed(2)}{" "}
                                    Billion
                                  </p>
                                )}
                              </p>

                              <p
                                style={{
                                  fontSize: "1rem",
                                  textAlign: "center",
                                  textTransform: "capitalize",
                                  marginBottom: "0em",
                                }}
                              >
                                {this.state.exposure_variable} Value at Risk
                                {parseFloat(
                                  this.state.losses["detailed"][
                                    `Temprature/heat loss`
                                  ]
                                ) < 1000 ? (
                                  <p>
                                    {" "}
                                    ${" "}
                                    {parseFloat(
                                      this.state.losses["detailed"][
                                        `Temprature/heat loss`
                                      ]
                                    )}{" "}
                                    Million
                                  </p>
                                ) : (
                                  <p>
                                    ${" "}
                                    {parseFloat(
                                      this.state.losses["detailed"][
                                        `Temprature/heat loss`
                                      ]
                                    )}{" "}
                                    Billion
                                  </p>
                                )}
                              </p>
                            </Col>
                          </Row>
                        ) : null}
                      </Row>
                      <br />
                      <Row>
                        <p
                          style={{
                            fontSize: "1.2rem",
                            color: "black",
                          }}
                        >
                          Value of Transition
                          <Icon
                            style={{
                              float: "right",
                              cursor: "pointer",
                            }}
                            name="info"
                            onClick={this.handleCardFindings_2}
                          />
                          {this.state.key_finding_data ? (
                            <p
                              style={{
                                color: "black",
                                fontSize: "1rem",
                              }}
                            >
                              $
                              {this.state.key_finding_data[
                                "value_of_transition_policies"
                              ].toFixed(2)}
                              &nbsp;Million
                            </p>
                          ) : null}
                        </p>
                      </Row>
                      <br />
                      <Button
                        style={{
                          color: "#18202F",
                          backgroundColor: "#fff",
                          borderColor: "#18202F",
                          border: "1px solid",
                        }}
                        variant="info"
                        onClick={this.handleKeyFindings_1}
                      >
                        Key Findings
                      </Button>
                    </Col>

                    <Col lg={6} className="marginhead">
                      <Row style={{ marginLeft: "10%" }}>
                        {this.state.overall.overall_risk ? (
                          <div className="severity">
                            {" "}
                            <h5
                              style={{
                                fontSize: "16px",
                                color: "black",
                              }}
                            >
                              {" "}
                              Climate Score
                              <Icon
                                style={{
                                  cursor: "pointer",
                                  padding: "1%",
                                }}
                                name="info"
                                onClick={this.handleCardFindings_4}
                              />
                            </h5>{" "}
                            <br />
                            <Progress
                              className="risk_severity"
                              type="circle"
                              percent={this.state.overall.overall_risk}
                              color="red"
                            >
                              {" "}
                            </Progress>
                          </div>
                        ) : null}
                      </Row>
                      <br />
                      {/* <Row>
                        {this.state.overall !== undefined ? (
                          <h5 style={{ fontSize: "0.9rem", fontWeight: "600" }}>
                            Technical Insurance Premium in % at &nbsp;
                            {this.state.overall.tip_in_perc}{" "}
                            <Icon
                              name="info"
                              onClick={this.handleKeyFindings_t}
                              style={{ cursor: "pointer" }}
                            />
                          </h5>
                        ) : null}
                        </Row>*/}
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Tier />
            )}

            <br />
            <Row>
              {this.state.subscription.overall_dashboard &&
              this.state.subscription.overall_dashboard.rcp_year_loss_bars ? (
                <Col
                  className="firstshadow"
                  lg={7}
                  xs={12}
                  sm={12}
                  style={{ maxHeight: "550px" }}
                >
                  <Row>
                    <Col lg={6} xs={12} sm={12}>
                      <Row className="mainheading">
                        {" "}
                        <p>
                          {" "}
                          <span className={"dark-mode-theme-color-1"}>
                            {" "}
                            Year Loss for {this.state.analysis.toUpperCase()}{" "}
                            {this.state.analysis == "rcp" ? (
                              <span>{this.state.rcp}</span>
                            ) : (
                              <span>{this.state.ssp}</span>
                            )}{" "}
                          </span>
                        </p>{" "}
                      </Row>

                      {this.state.financial ? (
                        <YEARDonut
                          data={this.state.yearDetail}
                          rcp={this.state.rcp}
                          year={this.state.year}
                          key_find={this.state.key_finding_data}
                          analysis={this.state.analysis}
                          ssp={this.state.ssp}
                        />
                      ) : (
                        <MdryearDonut
                          rcp={this.state.rcp}
                          year={this.state.year}
                          data={this.state.percentage}
                          key_find={this.state.key_finding_data}
                          analysis={this.state.analysis}
                          ssp={this.state.ssp}
                        />
                      )}
                    </Col>{" "}
                    <Col lg={6} xs={12} sm={12}>
                      <Row className="mainheading">
                        {" "}
                        <p>
                          {" "}
                          <span className={"dark-mode-theme-color-1"}>
                            {" "}
                            {this.state.analysis.toUpperCase()} Loss for Year{" "}
                            {this.state.year}{" "}
                          </span>
                        </p>{" "}
                      </Row>

                      {this.state.financial ? (
                        <RCPDonut
                          data={this.state.yearDetail}
                          year={this.state.year}
                          key_find={this.state.key_finding_data}
                          analysis={this.state.analysis}
                        />
                      ) : (
                        <MdrrcpDonut
                          year={this.state.year}
                          data={this.state.percentage}
                          key_find={this.state.key_finding_data}
                          analysis={this.state.analysis}
                        />
                      )}
                    </Col>{" "}
                  </Row>{" "}
                </Col>
              ) : (
                <Col lg={7}>
                  <Tier />
                </Col>
              )}
              {this.state.subscription.overall_dashboard &&
              this.state.subscription.overall_dashboard.portfolio_losses ? (
                <Col
                  id="Intensity"
                  className="firstshadowmap"
                  lg={{ span: 4 }}
                  xs={12}
                  sm={12}
                  style={{ maxHeight: "550px", overflowY: "auto" }}
                >
                  <Row>
                    <Col>
                      {this.state.losses["detailed"] ? (
                        <div>
                          {this.state.financial ? (
                            <>
                              <Row className="mainheading">
                                {" "}
                                <p style={{ marginLeft: "-3%" }}>
                                  <span className={"dark-mode-theme-color-1"}>
                                    Loss Contribution Analysis
                                  </span>
                                  <Button
                                    style={{
                                      color: "#18202F",
                                      backgroundColor: "#fff",
                                      borderColor: "#18202F",
                                      border: "1px solid",
                                      float: "right",
                                    }}
                                    variant="info"
                                    onClick={this.handleKeyFindings_3}
                                  >
                                    Key Findings
                                  </Button>
                                </p>
                              </Row>
                              <LossComponent
                                data={this.state.losses}
                                newdata={this.state.losses_2020}
                              />
                            </>
                          ) : (
                            <>
                              <Tabs defaultActiveKey="chart_b">
                                {/*<Tab
                                  title="Portfolio Exposure Analysis"
                                  eventKey="chart_a"
                                >
                                  <Row className="mainheading">
                                    {" "}
                                    <p style={{ marginLeft: "-3%" }}>
                                      <span
                                        className={"dark-mode-theme-color-1"}
                                      >
                                        Portfolio Exposure related Loss Analysis{" "}
                                        <Icon
                                          name="info"
                                          onClick={this.handleKeyFindings_p}
                                          style={{ cursor: "pointer" }}
                                        />
                                      </span>
                                      <Button
                                        style={{
                                          color: "#18202F",
                                          backgroundColor: "#fff",
                                          borderColor: "#18202F",
                                          border: "1px solid",
                                          float: "right",
                                        }}
                                        variant="info"
                                        onClick={this.handleKeyFindings_3}
                                      >
                                        Key Findings
                                      </Button>
                                    </p>
                                  </Row>
                                  <LossPercentNewComponent
                                    data={this.state.portfolio_exposure}
                                    newdata={
                                      this.state.portfolio_exposure_historic
                                    }
                                  />
                                  </Tab>*/}
                                <Tab
                                  title="Loss Contribution Analysis"
                                  eventKey="chart_b"
                                >
                                  <Row className="mainheading">
                                    {" "}
                                    <p style={{ marginLeft: "-3%" }}>
                                      <span
                                        className={"dark-mode-theme-color-1"}
                                      >
                                        Loss Contribution Analysis (% of Maximum
                                        Exposure)
                                      </span>
                                      <Button
                                        style={{
                                          color: "#18202F",
                                          backgroundColor: "#fff",
                                          borderColor: "#18202F",
                                          border: "1px solid",
                                          float: "right",
                                        }}
                                        variant="info"
                                        onClick={this.handleKeyFindings_3}
                                      >
                                        Key Findings
                                      </Button>
                                    </p>
                                  </Row>
                                  <LossPercentComponent
                                    data={this.state.losses}
                                    newdata={this.state.losses_2020}
                                  />
                                </Tab>
                              </Tabs>
                            </>
                          )}
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <hr
                        className="intense1"
                        style={{
                          borderTop: "2px solid #FF8080",
                          width: "20%",
                        }}
                      />
                      <hr
                        className="intense2"
                        style={{
                          borderTop: "2px solid #18202F",
                          width: "20%",
                        }}
                      />
                    </Col>
                    <Col>
                      Baseline Impact at 2020 <br />
                      Projected Impact
                    </Col>
                  </Row>
                </Col>
              ) : (
                <Col lg={4}>
                  <Tier />
                </Col>
              )}
            </Row>

            <br />

            <Row>
              {this.state.subscription.overall_dashboard &&
              this.state.subscription.overall_dashboard.asset_level_risk_map ? (
                <Col
                  className="firstshadow"
                  lg={7}
                  sm={12}
                  xs={12}
                  style={{
                    maxWidth: "95.6%",
                  }}
                >
                  <Row className="mainheading">
                    <Col>
                      <p style={{ marginLeft: "-6%" }}>
                        <span className={"dark-mode-theme-color-1"}>
                          Asset Level Risk Map
                        </span>
                      </p>
                    </Col>

                    <Col>
                      <Form.Select
                        placeholder="Hazard"
                        style={{
                          float: "right",
                          minHeight: "0.8em",

                          minWidth: "4em",
                          width: "16em",
                        }}
                        value={this.state.hazard_map}
                        onChange={this.handleHazard}
                        options={HazardOptions}
                      />
                    </Col>
                  </Row>

                  {/*  <Tab title="Map" eventKey="map">
                              <Col>
                                <Form.Select
                                  placeholder="Asset"
                                  style={{
                                    float: "right",
                                    minHeight: "0.8em",
                                    marginTop: "-1%",
                                    minWidth: "4em",
                                    width: "8em",
                                  }}
                                  value={asset}
                                  onChange={this.handleAsset}
                                  options={AssetOptions}
                                />
                              </Col>

                              {this.state.assets.length > 0 ? (
                                <Map
                                  data={this.state.mapVariable}
                                  lat={this.state.lat}
                                  long={this.state.long}
                                  zoom={this.state.zoom}
                                  locations={this.state.asset_table}
                                  style={{ height: "400px", padding: "10px" }}
                                />
                              ) : null}

                              <br />
                            </Tab>
                            <Tab title="RiskMap" eventKey="riskmap">
                              {this.state.country_loss &&
                              this.state.country_loss.length == undefined ? (
                                <RiskMap data={this.state.country_loss} />
                              ) : null}
                            </Tab>

                            <Tab title="LossMap" eventKey="lossmap" id="world">
                              {this.state.country_loss &&
                              this.state.country_loss.length == undefined ? (
                                <WorldMap data={this.state.country_loss} />
                              ) : null}
                            </Tab>*/}

                  <br />

                  {this.state.combined_data &&
                  this.state.hazard_map == "Rainfall Flood" ? (
                    <ClusterMap
                      hazard={this.state.hazard_map}
                      data={this.state.combined_data}
                      subscription={this.state.subscription}
                    />
                  ) : null}
                  {this.state.combined_data &&
                  this.state.hazard_map == "Extreme Heat" ? (
                    <ClusterMap
                      hazard={this.state.hazard_map}
                      data={this.state.combined_data}
                      subscription={this.state.subscription}
                    />
                  ) : null}

                  {this.state.combined_data &&
                  this.state.hazard_map == "River Flood" ? (
                    <ClusterMap
                      hazard={this.state.hazard_map}
                      data={this.state.combined_data}
                      subscription={this.state.subscription}
                    />
                  ) : null}
                  {this.state.combined_data &&
                  this.state.hazard_map == "Landslide" ? (
                    <ClusterMap
                      hazard={this.state.hazard_map}
                      data={this.state.combined_data}
                      subscription={this.state.subscription}
                    />
                  ) : null}
                  {this.state.combined_data &&
                  this.state.hazard_map == "Typhoon" ? (
                    <ClusterMap
                      hazard={this.state.hazard_map}
                      data={this.state.combined_data}
                      subscription={this.state.subscription}
                    />
                  ) : null}
                  {this.state.combined_data &&
                  this.state.hazard_map == "Drought" ? (
                    <ClusterMap
                      hazard={this.state.hazard_map}
                      data={this.state.combined_data}
                      subscription={this.state.subscription}
                    />
                  ) : null}
                  {this.state.combined_data &&
                  this.state.hazard_map == "Snow Melt" ? (
                    <ClusterMap
                      hazard={this.state.hazard_map}
                      data={this.state.combined_data}
                      subscription={this.state.subscription}
                    />
                  ) : null}
                  {this.state.combined_data &&
                  this.state.hazard_map == "Sea Level Rise" ? (
                    <ClusterMap
                      hazard={this.state.hazard_map}
                      data={this.state.combined_data}
                      subscription={this.state.subscription}
                    />
                  ) : null}
                  {this.state.combined_data &&
                  this.state.hazard_map == "Storm Surge" ? (
                    <ClusterMap
                      hazard={this.state.hazard_map}
                      data={this.state.combined_data}
                      subscription={this.state.subscription}
                    />
                  ) : null}
                  {this.state.combined_data &&
                  this.state.hazard_map == "Wild Fire" ? (
                    <ClusterMap
                      hazard={this.state.hazard_map}
                      data={this.state.combined_data}
                      subscription={this.state.subscription}
                    />
                  ) : null}
                </Col>
              ) : (
                <Col lg={7}>
                  <Tier />
                </Col>
              )}
              {this.state.subscription.overall_dashboard &&
              this.state.subscription.overall_dashboard
                .climate_variable_intensity ? (
                <Col
                  className="firstshadowmap"
                  lg={4}
                  xs={12}
                  sm={12}
                  style={{ overflowY: "auto", maxHeight: "644px" }}
                >
                  <Row className="mainheading">
                    <p>
                      <span className={"dark-mode-theme-color-1"}>
                        Climate Variable Intensity
                      </span>
                      <Button
                        style={{
                          color: "#18202F",
                          backgroundColor: "#fff",
                          borderColor: "#18202F",
                          border: "1px solid",
                          float: "right",
                        }}
                        variant="info"
                        onClick={this.handleKeyFindings_2}
                      >
                        Key Findings
                      </Button>
                    </p>
                  </Row>

                  <Row>
                    <Col>
                      <br />
                      {this.state.risk_2020 && this.state.hazard ? (
                        <Progress_bar
                          newdata={this.state.risk_2020}
                          hazard={this.state.hazard}
                          variable={this.state.variables}
                          activeIndex={this.state.activeIndex}
                        />
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <hr
                        className="intense1"
                        style={{
                          borderTop: "2px solid #FF8080",
                          width: "20%",
                        }}
                      />
                      <hr
                        className="intense2"
                        style={{
                          borderTop: "2px solid #18202F",
                          width: "20%",
                        }}
                      />
                    </Col>
                    <Col>
                      Baseline Impact at 2020 <br />
                      Projected Impact
                    </Col>
                  </Row>
                </Col>
              ) : (
                <Col lg={4}>
                  <Tier />
                </Col>
              )}

              {/* <Col lg={{ span: 4 }} xs={12} sm={12} className="firstshadowmap dark-mode-black">

                                    <Row className="mainheading" style={{ width: '100%' }}><p style={{ width: '85%' }}><span className={"dark-mode-theme-color-1"}>Climate Risk Index <Icon name="info circle" style={{cursor:'pointer'}} onClick={this.handleRiskOpen}/></span></p>  </Row>





                                    <Row style={{ height: '60vh' }}>

                                        <BarComponent data={this.state.feedback} />




                                    </Row>
                        </Col>*/}
            </Row>
            <br />

            {this.state.subscription.overall_dashboard &&
            this.state.subscription.overall_dashboard.climate_heatmap ? (
              <Row style={{ backgroundColor: "white" }}>
                {/*<Col
                          lg={7}
                          xs={12}
                          sm={12}
                          className="firstshadow"
                          style={{
                            height: "550px",
                            overflowX: "auto",
                            overflowY: "hidden",
                          }}
                        >
                          <CandleStick
                            data={this.state.overall.candle_stick_chart}
                          />
                        </Col>*/}
                <Col
                  className="firstshadowheat"
                  lg={12}
                  xs={16}
                  sm={16}
                  style={{
                    maxWidth: "95.6%",
                    maxHeight: "550px",
                    overflowY: "auto",
                  }}
                >
                  <Row
                    className="mainheading sticky-top"
                    style={{ backgroundColor: "white" }}
                  >
                    <p style={{ marginBottom: "0em" }}>
                      <span className={"dark-mode-theme-color-1"}>
                        Climate Risk Heat Map{" "}
                      </span>
                    </p>
                    <Row
                      style={{
                        backgroundColor: "white",
                        width: "100%",
                      }}
                    >
                      <Col lg={3}></Col>
                      <Col lg={6}>
                        <Image src={legend_h} height="60" />
                      </Col>
                      <Col lg={3}></Col>
                      {/*<Image src={hazard_h} className="hazard_legend" />*/}
                    </Row>
                  </Row>
                  <br />
                  <br />
                  <br />
                  <Row>
                    <Col lg={12}>
                      <Row style={{ height: "400px", width: "95%" }}>
                        {this.state.heatmap ? (
                          <Classicheatmap
                            data={this.state.heatmap}
                            variable={this.state.variables}
                          />
                        ) : null}
                      </Row>
                    </Col>
                    {/* <Col lg={2}>
                              <Image src={legend} />
                              </Col>*/}
                  </Row>
                </Col>
              </Row>
            ) : (
              <Col lg={12}>
                <Tier />
              </Col>
            )}
            <br />

            <Row></Row>
            <br />
            {this.state.subscription.overall_dashboard &&
            this.state.subscription.overall_dashboard.asset_table ? (
              <Row>
                <Col
                  className="firstshadowtable"
                  lg={12}
                  xs={16}
                  sm={16}
                  style={{
                    maxWidth: "95.6%",
                    maxHeight: "550px",
                    overflowY: "auto",
                    padding: "0%!important",
                  }}
                >
                  <Row className="mainheading">
                    {" "}
                    <p>
                      <span className={"dark-mode-theme-color-1"}>
                        Asset Table{" "}
                        <Icon
                          name="info"
                          onClick={this.handleKeyFindings_4}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    </p>
                  </Row>

                  <Row>
                    <Col>
                      {/* <Select
                                style={{ float: "right" }}
                                placeholder="Select"
                                options={tableOptions}
                                onChange={(e, { value }) =>
                                  this.handleTable(value, "table")
                                }
                                value={table}
                              />*/}

                      {this.state.table_condition && !this.state.loading ? (
                        <EditableTable
                          data={this.state.asset_table}
                          open={this.handleOpen}
                          permission={this.state.edit_permission}
                          update={this.Edit}
                          info={this.MitigationInfo}
                        />
                      ) : (
                        <Spinner />
                      )}

                      {/*   <Tab title="Overall Loss" eventKey="Loss">
                                  <br />{" "}
                                  {this.state.table_condition && (
                                    <EditableLossTable
                                      data={this.state.asset_table}
                                      open={this.handleOpen}
                                      update={this.Edit}
                                      table={this.state.table}
                                    />
                                  )}
                                </Tab>
                                <Tab title="Climate  Score" eventKey="Climate">
                                  <br />{" "}
                                  {this.state.table_condition && (
                                    <EditableClimateTable
                                      data={this.state.asset_table}
                                      open={this.handleOpen}
                                      update={this.Edit}
                                      table={this.state.table}
                                    />
                                  )}
                                </Tab>
                                <Tab title="Value" eventKey="Value">
                                  <br />
                                  {this.state.table_condition && (
                                    <EditableValuationTable
                                      data={this.state.asset_table}
                                      open={this.handleOpen}
                                      update={this.Edit}
                                      table={this.state.table}
                                    />
                                  )}
                                  </Tab>*/}
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Tier />
            )}
            {/* <Row>
                        <Col
                          className="firstshadow"
                          lg={12}
                          xs={16}
                          sm={16}
                          style={{
                            maxWidth: "95.6%",
                            maxHeight: "550px",
                            overflowY: "auto",
                          }}
                        >
                          <Row className="mainheading">
                            {" "}
                            <p>
                              <span className={"dark-mode-theme-color-1"}>
                                Flood Mitigation Measures{" "}
                              </span>
                            </p>
                          </Row>
                          <Row>
                            <Col lg={8}></Col>
                            <Col>
                              <Form.Select
                                placeholder="High"
                                style={{
                                  float: "right",
                                  minHeight: "0.8em",

                                  minWidth: "4em",
                                  width: "16em",
                                }}
                                value={this.state.metigation_measures}
                                onChange={this.handleMitigationMeasure}
                                options={MitigationMeasureOptions}
                              />
                            </Col>
                            <Col>
                              <Form.Select
                                placeholder="River Flood"
                                style={{
                                  float: "right",
                                  minHeight: "0.8em",

                                  minWidth: "4em",
                                  width: "16em",
                                }}
                                value={this.state.mitigation_variable}
                                onChange={this.handleMitigation}
                                options={MitigationOptions}
                              />
                            </Col>
                          </Row>
                          {this.state.metigation ? (
                            <MitigationBar
                              metigation_measure={this.state.metigation_measure}
                              mitigation_variable={
                                this.state.mitigation_variable
                              }
                            />
                          ) : null}
                        </Col>
                      </Row>*/}
          </>
          {/* <Tab title="DETAILED" eventKey="Detailed">
                  <Detail
                    rcp={this.state.rcp}
                    year={this.state.year}
                    project={this.state.project}
                    detailed={this.state.detailed}
                    handleDetail={this.handleDetail}
                    asset={this.state.asset_table}
                                  />
                </Tab>

                <Tab title="HAZARDS" eventKey="hazards">
                  <div className="note-hazards">
                    <p
                      style={{
                        fontSize: "18px",
                        color: "#18202F",
                        float: "right",
                        marginRight: "50px",
                      }}
                    >
                      Hover-Over the icons to go through the information on the
                      hover wheel
                    </p>
                  </div>
                  <div class="parent_div">
                    <div class="circle_3">
                      <p class="text-center">
                        {" "}
                        <span
                          class="mytooltip tooltip-effect-1"
                          style={{ marginTop: "200px" }}
                        >
                          {" "}
                          <span class="tooltip-item">
                            <img
                              class="windimg"
                              src={wind}
                              style={{
                                height: "50px",
                                border: "2px solid #000",
                              }}
                            />
                          </span>{" "}
                          <span class="tooltip-content clearfix">
                            <span class="tooltip-text">
                              {" "}
                              <h2>Extreme Wind Speeds</h2> <br /> Maximum wind
                              speed at multiple return periods <br />
                              <br />
                              Fraction of building, contents and inventory
                              damaged by extreme wind events <br />
                              <br />
                              Annual average loss to building, contents and
                              inventory
                              <br />
                              Number of days of business interruption
                            </span>{" "}
                          </span>{" "}
                        </span>
                      </p>

                      <p class="text-center">
                        {" "}
                        <span class="mytooltip tooltip-effect-1">
                          {" "}
                          <span class="tooltip-item">
                            <img
                              class="rainfallimg"
                              src={rainfall}
                              style={{
                                height: "50px",
                                border: "2px solid #000",
                              }}
                            />
                          </span>{" "}
                          <span class="tooltip-content clearfix">
                            <span class="tooltip-text">
                              <h2>Extreme Rainfall</h2>
                              <br />
                              Maximum daily precipitation across multiple return
                              periods
                            </span>{" "}
                          </span>{" "}
                        </span>
                      </p>

                      <div>
                        <p class="text-center">
                          {" "}
                          <span class="mytooltip tooltip-effect-1">
                            {" "}
                            <span class="tooltip-item">
                              <img
                                class="fireimg"
                                src={fire}
                                style={{
                                  height: "50px",
                                  border: "2px solid #000",
                                }}
                              />
                            </span>{" "}
                            <span class="tooltip-content clearfix">
                              <span class="tooltip-text">
                                <h2>Wildfire Risk</h2>
                                <br />
                                The annual number of wildfires, which ignite and
                                grow to detectable size, <br />
                                that are expected in a 1 square km grid cell
                              </span>{" "}
                            </span>{" "}
                          </span>
                        </p>

                        <p class="text-center">
                          {" "}
                          <span class="mytooltip tooltip-effect-1">
                            {" "}
                            <span class="tooltip-item">
                              <img
                                class="stormimg"
                                src={storm}
                                style={{
                                  height: "50px",
                                  border: "2px solid #000",
                                }}
                              />
                            </span>{" "}
                            <span class="tooltip-content clearfix">
                              <span class="tooltip-text">
                                <h2>Hail And Thunderstorm Probability</h2>
                                <br />
                                Number of days per year where large hail (>2 in
                                / 5 cm in diameter) is possible
                                <br />
                                <br />
                                Number of days per year where environmental
                                conditions are conducive to severe thunderstorm
                                formation
                              </span>{" "}
                            </span>{" "}
                          </span>
                        </p>
                      </div>

                      <p class="text-center">
                        {" "}
                        <span class="mytooltip tooltip-effect-1">
                          {" "}
                          <span class="tooltip-item">
                            <img
                              class="droughtimg"
                              src={drought}
                              style={{
                                height: "50px",
                                border: "2px solid #000",
                              }}
                            />
                          </span>{" "}
                          <span class="tooltip-content clearfix">
                            <span class="tooltip-text">
                              <h2>Drought Frequency</h2>
                              <br />
                              Months per year where the rolling 3-month or
                              6-month average
                              <br />
                              <br />
                              Standardized Precipitation Evapotranspiration
                              Index (SPEI) is below -2
                            </span>{" "}
                          </span>{" "}
                        </span>
                      </p>

                      <p class="text-center">
                        {" "}
                        <span class="mytooltip tooltip-effect-1">
                          {" "}
                          <span class="tooltip-item">
                            <img
                              class="floodimg"
                              src={flood}
                              style={{
                                height: "50px",
                                border: "2px solid #000",
                              }}
                            />
                          </span>{" "}
                          <span
                            class="tooltip-content clearfix"
                            style={{ marginBottom: "20px" }}
                          >
                            <span
                              class="tooltip-text"
                              style={{ marginBottom: "20px" }}
                            >
                              <h2>Flood Depth of Water</h2>
                              <br />
                              Flood depth of water across multiple return
                              periods
                              <br />
                              <br />
                              Percentage of the central square flooded across
                              multiple return periods
                              <br />
                              <br />
                              Fraction of building, contents and inventory
                              damaged by flood events
                            </span>{" "}
                          </span>{" "}
                        </span>
                      </p>

                      <p class="text-center">
                        {" "}
                        <span
                          class="mytooltip tooltip-effect-1"
                          style={{ marginBottom: "50px" }}
                        >
                          {" "}
                          <span class="tooltip-item">
                            <img
                              class="heatimg"
                              src={heat}
                              style={{
                                height: "50px",
                                border: "2px solid #000",
                              }}
                            />
                          </span>{" "}
                          <span
                            class="tooltip-content clearfix"
                            style={{ marginBottom: "20px" }}
                          >
                            <span
                              class="tooltip-text"
                              style={{ marginBottom: "20px" }}
                            >
                              <h2>Extreme Heat</h2>
                              <br />
                              Days per year exceeding 35°C, 38°C and the
                              historical 99th percentile
                              <br />
                              <br />
                              Heating and cooling degree days
                              <br />
                              <br />
                              Days exceeding high (32°C) and dangerously high
                              (35°C) Wet-Bulb Globe Temperature
                            </span>{" "}
                          </span>{" "}
                        </span>
                      </p>
                      <div class="circle_2">
                        <div class="fillerdiv"></div>
                        <div class="circle_mt">
                          <div class="text_mt">HAZARDS</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>*/}
        </Container>
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          closeIcon
          className="individual"
          itemName={this.state.activeItemName}
          size="fullscreen"
        >
          <Modal.Header
            style={isDarkMode ? { backgroundColor: "#001244" } : {}}
          >
            Asset Analysis - {this.state.activeItemName}
          </Modal.Header>
          <Modal.Content
            style={isDarkMode ? { backgroundColor: "#001244" } : {}}
          >
            <br />
            <Row>
              <Col>
                <Tabs defaultActiveKey="Risk">
                  <Tab eventKey="Risk" title="Risk">
                    <br />
                    <Container fluid={true}>
                      <Row>
                        {this.state.single_asset_overall ? (
                          <Col className="card" xs={16} sm={16} lg={4}>
                            <Row className="mainheading">
                              <p>
                                {this.state.analysis == "rcp" ? (
                                  <span
                                    className={"dark-mode-theme-color-1"}
                                    style={{
                                      fontWeight: "500",
                                      color: "black",
                                    }}
                                  >
                                    Physical Climate Risk for RCP&nbsp;
                                    {this.state.rcp} {this.state.year}
                                  </span>
                                ) : null}
                                {this.state.analysis == "ssp" ? (
                                  <span
                                    className={"dark-mode-theme-color-1"}
                                    style={{
                                      fontWeight: "500",
                                      color: "black",
                                    }}
                                  >
                                    Physical Climate Risk for SSP&nbsp;
                                    {this.state.ssp} {this.state.year}
                                  </span>
                                ) : null}
                              </p>
                            </Row>

                            <Row style={{ padding: "2%" }}>
                              <Col lg={6}>
                                <Row>
                                  {this.state.exposure_variable == "Total" &&
                                  this.state.single_asset_loss["total"] ? (
                                    <p
                                      style={{
                                        fontSize: "1.2rem",
                                        color: "black",
                                      }}
                                    >
                                      {" "}
                                      Climate Value at Risk{" "}
                                      {this.state.single_asset_loss["total"][
                                        "Total Loss"
                                      ][0] < 1000 ? (
                                        <p
                                          style={{
                                            fontSize: "1rem",
                                            color: "black",
                                          }}
                                        >
                                          $
                                          {this.state.single_asset_loss[
                                            "total"
                                          ]["Total Loss"][0].toFixed(2)}
                                          &nbsp;Million
                                        </p>
                                      ) : (
                                        <p
                                          style={{
                                            fontSize: "1rem",
                                            color: "black",
                                          }}
                                        >
                                          $
                                          {(
                                            this.state.single_asset_loss[
                                              "total"
                                            ]["Total Loss"][0] / 1000
                                          ).toFixed(2)}
                                          &nbsp;Billion
                                        </p>
                                      )}{" "}
                                    </p>
                                  ) : null}
                                </Row>
                                <br />
                                <Row>
                                  <p
                                    style={{
                                      fontSize: "1.2rem",
                                      color: "black",
                                    }}
                                  >
                                    {" "}
                                    Maximum Exposure
                                    <br />
                                    {this.state.single_asset_overall[0][
                                      "Max_exposure"
                                    ] < 1000 ? (
                                      <p
                                        style={{
                                          fontSize: "1rem",
                                          color: "black",
                                        }}
                                      >
                                        $
                                        {this.state.single_asset_overall[0][
                                          "Max_exposure"
                                        ].toFixed(2)}{" "}
                                        Million
                                      </p>
                                    ) : (
                                      <p>
                                        $
                                        {(
                                          this.state.single_asset_overall[0][
                                            "Max_exposure"
                                          ] / 1000
                                        ).toFixed(2)}{" "}
                                        Billion
                                      </p>
                                    )}{" "}
                                  </p>
                                  {this.state.single_asset_loss[
                                    "Asset River Flood Damage"
                                  ] &&
                                  this.state.exposure_variable != "Total" &&
                                  this.state.exposure_variable !=
                                    "Extreme heat" ? (
                                    <Row>
                                      <Col
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          width: "100%",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize: "1rem",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {" "}
                                          {this.state.exposure_variable}
                                          Exposure
                                          <br />
                                          {this.state.single_asset_overall[0][
                                            `${this.state.exposure_variable}_exposure`
                                          ] < 1000 ? (
                                            <p>
                                              {" "}
                                              ${" "}
                                              {this.state.single_asset_overall[0][
                                                `${this.state.exposure_variable}_exposure`
                                              ].toFixed(2)}
                                              Million{" "}
                                            </p>
                                          ) : (
                                            <p>
                                              ${" "}
                                              {(
                                                this.state
                                                  .single_asset_overall[0][
                                                  `${this.state.exposure_variable}_exposure`
                                                ] / 1000
                                              ).toFixed(2)}
                                              Billion{" "}
                                            </p>
                                          )}{" "}
                                        </p>

                                        <Tooltip
                                          title={`Operational Loss: $ ${this.state.single_asset_loss[
                                            `Operational ${this.state.exposure_variable} Loss`
                                          ][0].toFixed(2)} Mn
                    Damage Loss $ ${this.state.single_asset_loss[
                      `Asset ${this.state.exposure_variable} Damage`
                    ][0].toFixed(2)} Mn`}
                                        >
                                          <p
                                            style={{
                                              fontSize: "1rem",
                                              fontWeight: "bold",
                                              textTransform: "capitalize",
                                              marginBottom: "0em",
                                            }}
                                          >
                                            {this.state.exposure_variable} Value
                                            at Risk
                                          </p>

                                          {parseFloat(
                                            this.state.single_asset_loss[
                                              `Asset ${this.state.exposure_variable} Damage`
                                            ]
                                          ) +
                                            parseFloat(
                                              this.state.single_asset_loss[
                                                `Operational ${this.state.exposure_variable} Loss`
                                              ]
                                            ) <
                                          1000 ? (
                                            <p>
                                              {" "}
                                              ${" "}
                                              {(
                                                parseFloat(
                                                  this.state.single_asset_loss[
                                                    `Asset ${this.state.exposure_variable} Damage`
                                                  ]
                                                ) +
                                                parseFloat(
                                                  this.state.single_asset_loss[
                                                    `Operational ${this.state.exposure_variable} Loss`
                                                  ]
                                                )
                                              ).toFixed(2)}{" "}
                                              Million
                                            </p>
                                          ) : (
                                            <p>
                                              ${" "}
                                              {(
                                                (parseFloat(
                                                  this.state.single_asset_loss[
                                                    `Asset ${this.state.exposure_variable} Damage`
                                                  ]
                                                ) +
                                                  parseFloat(
                                                    this.state
                                                      .single_asset_loss[
                                                      `Operational ${this.state.exposure_variable} Loss`
                                                    ]
                                                  )) /
                                                1000
                                              ).toFixed(2)}{" "}
                                              Billion
                                            </p>
                                          )}
                                        </Tooltip>
                                      </Col>
                                    </Row>
                                  ) : null}
                                  {this.state.single_asset_loss[
                                    "Asset River Flood Damage"
                                  ] &&
                                  this.state.exposure_variable ==
                                    "Extreme heat" ? (
                                    <Row>
                                      <Col
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          width: "100%",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize: "1rem",
                                            textAlign: "center",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {this.state.exposure_variable}{" "}
                                          Exposure
                                          <br />
                                          {this.state.single_asset_overall[0][
                                            `${this.state.exposure_variable}_exposure`
                                          ] < 1000 ? (
                                            <p>
                                              ${" "}
                                              {this.state.single_asset_overall[0][
                                                `${this.state.exposure_variable}_exposure`
                                              ].toFixed(2)}{" "}
                                              Million
                                            </p>
                                          ) : (
                                            <p>
                                              ${" "}
                                              {(
                                                this.state
                                                  .single_asset_overall[0][
                                                  `${this.state.exposure_variable}_exposure`
                                                ] / 1000
                                              ).toFixed(2)}{" "}
                                              Billion
                                            </p>
                                          )}
                                        </p>

                                        <p
                                          style={{
                                            fontSize: "1rem",
                                            textAlign: "center",
                                            textTransform: "capitalize",
                                            marginBottom: "0em",
                                          }}
                                        >
                                          {this.state.exposure_variable} Value
                                          at Risk
                                          {parseFloat(
                                            this.state.single_asset_loss[
                                              `Temprature/heat loss`
                                            ]
                                          ) < 1000 ? (
                                            <p>
                                              {" "}
                                              ${" "}
                                              {parseFloat(
                                                this.state.single_asset_loss[
                                                  `Temprature/heat loss`
                                                ]
                                              )}{" "}
                                              Million
                                            </p>
                                          ) : (
                                            <p>
                                              ${" "}
                                              {parseFloat(
                                                this.state.single_asset_loss[
                                                  `Temprature/heat loss`
                                                ]
                                              )}{" "}
                                              Billion
                                            </p>
                                          )}
                                        </p>
                                      </Col>
                                    </Row>
                                  ) : null}
                                </Row>
                                <br />

                                <Row>
                                  <p
                                    style={{
                                      fontSize: "1.2rem",
                                    }}
                                  >
                                    Value of Transition
                                    {this.state.single_asset_overall ? (
                                      <p
                                        style={{
                                          fontSize: "1rem",
                                        }}
                                      >
                                        ${" "}
                                        {this.state.single_asset_overall[0][
                                          "value_of_transition"
                                        ].toFixed(2)}
                                        &nbsp; Million
                                      </p>
                                    ) : null}
                                  </p>
                                </Row>
                              </Col>
                              <Col
                                lg={6}
                                className="marginhead"
                                style={{ textAlign: "center" }}
                              >
                                <Row>
                                  {this.state.single_asset_overall[0]
                                    .per_10_years_rise ? (
                                    <div
                                      className="severity_single"
                                      style={{ width: "80%" }}
                                    >
                                      {" "}
                                      <h5
                                        style={{
                                          fontSize: "16px",
                                          color: "black",
                                        }}
                                      >
                                        {" "}
                                        Climate Score
                                      </h5>{" "}
                                      <br />
                                      <Progress
                                        className="risk_severity"
                                        type="circle"
                                        percent={
                                          this.state.single_asset_overall[0]
                                            .per_10_years_rise
                                        }
                                        color="red"
                                      >
                                        {" "}
                                      </Progress>
                                    </div>
                                  ) : null}
                                </Row>
                                <br />
                                {/* <Row>
                                  {this.state.single_asset_overall[0] !==
                                  undefined ? (
                                    <h5
                                      style={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Technical Insurance Premium in % at &nbsp;
                                      {
                                        this.state.single_asset_overall[0]
                                          .tip_in_perc
                                      }
                                    </h5>
                                  ) : null}
                                    </Row>*/}
                              </Col>
                            </Row>
                          </Col>
                        ) : null}

                        {this.state.singleYearDetail ? (
                          <Col xs={16} sm={16} lg={4}>
                            <Row className="mainheading">
                              <p>
                                {" "}
                                <span className={"dark-mode-theme-color-1"}>
                                  {" "}
                                  Year Loss for{" "}
                                  {this.state.analysis.toUpperCase()}{" "}
                                  {this.state.analysis == "rcp" ? (
                                    <span>{this.state.rcp}</span>
                                  ) : (
                                    <span>{this.state.ssp}</span>
                                  )}{" "}
                                </span>
                              </p>
                            </Row>

                            {this.state.financial ? (
                              <SingleYEARDonut
                                data={this.state.singleYearDetail}
                                rcp={this.state.rcp}
                                ssp={this.state.ssp}
                                year={this.state.year}
                                analysis={this.state.analysis}
                              />
                            ) : (
                              <SingleMdryearDonut
                                rcp={this.state.rcp}
                                year={this.state.year}
                                data={this.state.singlePercentage}
                                analysis={this.state.analysis}
                                ssp={this.state.ssp}
                              />
                            )}
                          </Col>
                        ) : null}
                        <Col xs={16} sm={16} lg={4}>
                          <Row className="mainheading">
                            <p>
                              {" "}
                              <span className={"dark-mode-theme-color-1"}>
                                {" "}
                                {this.state.analysis.toUpperCase()} Loss for
                                Year {this.state.year}{" "}
                              </span>
                            </p>
                          </Row>

                          {this.state.financial ? (
                            <SingleRCPDonut
                              data={this.state.singleYearDetail}
                              year={this.state.year}
                              analysis={this.state.analysis}
                            />
                          ) : (
                            <SingleMdrrcpDonut
                              year={this.state.year}
                              data={this.state.singlePercentage}
                              analysis={this.state.analysis}
                            />
                          )}
                        </Col>
                      </Row>
                      <Row>
                        {/*   <Col
                          lg={4}
                          sm={16}
                          xs={16}
                          className="card dark-mode-black"
                          style={{ height: "600px" }}
                        >
                          <Row className="mainheading">
                            <p>
                              <span className={"dark-mode-theme-color-1"}>
                                Climate Score Table
                              </span>
                            </p>
                          </Row>
                          {this.state.single_asset_overall[0] ? (
                            <BarComponent
                              data={
                                this.state.single_asset_overall[0]
                                  .overall_bar_chart
                              }
                            />
                          ) : null}
                            </Col>*/}

                        <Col lg={4} sm={16} xs={16} className="card">
                          <Row>
                            <Row className="mainheading">
                              <p>
                                <span className={"dark-mode-theme-color-1"}>
                                  Asset Map Location
                                </span>
                              </p>
                            </Row>
                            {this.state.mapAsset ? (
                              <PopupMap data={this.state.mapAsset} />
                            ) : null}
                          </Row>
                          <br />
                          {/*  <Row>
                            {this.state.single_asset_hazard &&
                            this.state.single_asset_hazard_2020 ? (
                              <Progress_bar_asset
                                hazard={this.state.single_asset_hazard}
                                newdata={this.state.single_asset_hazard_2020}
                                data={this.state.single_asset_progress}
                                variable={this.state.variables}
                              />
                            ) : null}
                            </Row>*/}
                        </Col>

                        <Col lg={4} sm={16} xs={16} className="card">
                          <Row className="mainheading">
                            {" "}
                            <p>
                              <span
                                className={"dark-mode-theme-color-1"}
                                style={{ width: "100%" }}
                              >
                                Climate Variable Intensity
                              </span>
                            </p>
                          </Row>

                          <div>
                            <Row>
                              <Col>
                                {this.state.single_asset_hazard &&
                                this.state.single_asset_hazard_2020 ? (
                                  <Progress_bar
                                    hazard={this.state.single_asset_hazard}
                                    newdata={
                                      this.state.single_asset_hazard_2020
                                    }
                                    data={this.state.single_asset_progress}
                                    variable={this.state.variables}
                                  />
                                ) : null}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <hr
                                  className="intense1"
                                  style={{
                                    borderTop: "2px solid #FF8080",
                                    width: "20%",
                                  }}
                                />
                                <hr
                                  className="intense2"
                                  style={{
                                    borderTop: "2px solid #18202F",
                                    width: "20%",
                                  }}
                                />
                              </Col>
                              <Col>
                                Baseline Impact at 2020 <br />
                                Projected Impact
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        {this.state.single_asset_loss["detailed"] ? (
                          <Col lg={4} xs={16} sm={16} className="card">
                            <Row>
                              <Col>
                                {this.state.losses["detailed"] ? (
                                  <div>
                                    {this.state.financial ? (
                                      <>
                                        <Row className="mainheading">
                                          {" "}
                                          <p style={{ marginLeft: "-3%" }}>
                                            <span
                                              className={
                                                "dark-mode-theme-color-1"
                                              }
                                            >
                                              Loss Contribution Analysis
                                            </span>
                                          </p>
                                        </Row>
                                        <LossComponent
                                          data={this.state.single_asset_loss}
                                          newdata={
                                            this.state.single_asset_loss_2020
                                          }
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <Tabs defaultActiveKey="chart_b">
                                          {/*  <Tab
                                            title="Portfolio Exposure Analysis"
                                            eventKey="chart_a"
                                          >
                                            <Row className="mainheading">
                                              {" "}
                                              <p style={{ marginLeft: "-3%" }}>
                                                <span
                                                  className={
                                                    "dark-mode-theme-color-1"
                                                  }
                                                >
                                                  Portfolio Exposure related
                                                  Loss Analysis{" "}
                                                  {/*   <Icon
                                                    name="info"
                                                    onClick={this.handleKeyFindings_p}
                                                    style={{ cursor: "pointer" }}
                                    />
                                                </span>
                                              </p>
                                            </Row>
                                            <LossPercentNewComponent
                                              data={
                                                this.state
                                                  .single_asset_portfolio_exposure
                                              }
                                              newdata={
                                                this.state
                                                  .single_asset_portfolio_exposure_historic
                                              }
                                            />
                                            </Tab>*/}
                                          <Tab
                                            title="Loss Contribution Analysis"
                                            eventKey="chart_b"
                                          >
                                            <Row className="mainheading">
                                              {" "}
                                              <p style={{ marginLeft: "-3%" }}>
                                                <span
                                                  className={
                                                    "dark-mode-theme-color-1"
                                                  }
                                                >
                                                  Loss Contribution Analysis (%
                                                  of Maximum Exposure)
                                                </span>
                                              </p>
                                            </Row>
                                            <LossPercentComponent
                                              data={
                                                this.state.single_asset_loss
                                              }
                                              newdata={
                                                this.state
                                                  .single_asset_loss_2020
                                              }
                                            />
                                          </Tab>
                                        </Tabs>
                                      </>
                                    )}
                                  </div>
                                ) : null}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <hr
                                  className="intense1"
                                  style={{
                                    borderTop: "2px solid #FF8080",
                                    width: "20%",
                                  }}
                                />
                                <hr
                                  className="intense2"
                                  style={{
                                    borderTop: "2px solid #18202F",
                                    width: "20%",
                                  }}
                                />
                              </Col>
                              <Col>
                                Baseline Impact at 2020 <br />
                                Projected Impact
                              </Col>
                            </Row>
                          </Col>
                        ) : null}
                      </Row>
                    </Container>
                  </Tab>
                  <Tab eventKey="Comparison" title="Comparison">
                    <br />
                    <Container fluid={true}>
                      <Row>
                        <Col
                          lg={12}
                          xs={16}
                          sm={16}
                          className="card dark-mode-black"
                        >
                          <Row className="mainheading">
                            <p>
                              <span className={"dark-mode-theme-color-1"}>
                                {this.state.analysis.toUpperCase()} 2.6 vs{" "}
                                {this.state.analysis.toUpperCase()} 4.5 vs{" "}
                                {this.state.analysis.toUpperCase()} 8.5 for Year
                                2050
                              </span>
                            </p>
                          </Row>
                          {this.state.RcpData && this.state.RcpData.data ? (
                            <Column {...this.state.RcpData} />
                          ) : null}
                          {/* <ResponsiveBar
                            data={this.state.RcpData}
                            keys={["2.6", "4.5", "8.5"]}
                            indexBy="name"
                            margin={{
                              top: 50,
                              right: 90,
                              bottom: 50,
                              left: 60,
                            }}
                            padding={0.3}
                            enableContainerY={false}
                            groupMode="grouped"
                            valueScale={{ type: "linear" }}
                            indexScale={{ type: "band", round: true }}
                            colors={{ scheme: "category10" }}
                            defs={[
                              {
                                id: "dots",
                                type: "patternDots",
                                background: "inherit",
                                color: "#38bcb2",
                                size: 4,
                                padding: 1,
                                stagger: true,
                              },
                              {
                                id: "lines",
                                type: "patternLines",
                                background: "inherit",
                                color: "#eed312",
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10,
                              },
                            ]}
                            fill={[
                              {
                                match: {
                                  id: "fries",
                                },
                                id: "dots",
                              },
                              {
                                match: {
                                  id: "sandwich",
                                },
                                id: "lines",
                              },
                            ]}
                            borderColor={{
                              from: "color",
                              modifiers: [["darker", 1.6]],
                            }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: 0,

                              legendPosition: "middle",
                              legendOffset: 32,
                            }}
                            axisLeft={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: 0,
                              legend: "Climate  Risk  Index",
                              legendPosition: "middle",
                              legendOffset: -40,
                            }}
                            enableLabel={false}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{
                              from: "color",
                              modifiers: [["darker", 1.6]],
                            }}
                            legends={[
                              {
                                dataFrom: "keys",
                                anchor: "bottom-right",
                                direction: "column",
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: "left-to-right",
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                  {
                                    on: "hover",
                                    style: {
                                      itemOpacity: 1,
                                    },
                                  },
                                ],
                              },
                            ]}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                            theme={{
                              textColor: isDarkMode ? "#ffffff" : "#3a3a3a",
                            }}
                          />*/}
                        </Col>

                        <Col
                          lg={12}
                          xs={16}
                          sm={16}
                          className="card dark-mode-black"
                        >
                          <Row className="mainheading">
                            {" "}
                            {this.state.analysis == "rcp" ? (
                              <p>
                                <span className={"dark-mode-theme-color-1"}>
                                  Year 2020 vs Year 2030 vs Year 2050 for{" "}
                                  {this.state.analysis.toUpperCase()}{" "}
                                  {this.state.rcp}
                                </span>
                              </p>
                            ) : (
                              <p>
                                <span className={"dark-mode-theme-color-1"}>
                                  Year 2020 vs Year 2030 vs Year 2050 vs Year
                                  2100 for {this.state.analysis.toUpperCase()}{" "}
                                  {this.state.ssp}
                                </span>
                              </p>
                            )}
                          </Row>
                          {/*  <ResponsiveBar
                            data={this.state.YearData}
                            keys={["2020", "2030", "2050"]}
                            indexBy="name"
                            margin={{
                              top: 50,
                              right: 90,
                              bottom: 50,
                              left: 60,
                            }}
                            padding={0.3}
                            enableContainerY={false}
                            groupMode="grouped"
                            valueScale={{ type: "linear" }}
                            indexScale={{ type: "band", round: true }}
                            colors={["#8CB0F8", "#18202F", "#256EFF"]}
                            defs={[
                              {
                                id: "dots",
                                type: "patternDots",
                                background: "inherit",
                                color: "#38bcb2",
                                size: 4,
                                padding: 1,
                                stagger: true,
                              },
                              {
                                id: "lines",
                                type: "patternLines",
                                background: "inherit",
                                color: "#eed312",
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10,
                              },
                            ]}
                            fill={[
                              {
                                match: {
                                  id: "fries",
                                },
                                id: "dots",
                              },
                              {
                                match: {
                                  id: "sandwich",
                                },
                                id: "lines",
                              },
                            ]}
                            borderColor={{
                              from: "color",
                              modifiers: [["darker", 1.6]],
                            }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: 0,

                              legendPosition: "middle",
                              legendOffset: 32,
                            }}
                            axisLeft={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: 0,
                              legend: "Climate  Risk  Index",
                              legendPosition: "middle",
                              legendOffset: -40,
                            }}
                            enableLabel={false}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{
                              from: "color",
                              modifiers: [["darker", 1.6]],
                            }}
                            legends={[
                              {
                                dataFrom: "keys",
                                anchor: "bottom-right",
                                direction: "column",
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: "left-to-right",
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                  {
                                    on: "hover",
                                    style: {
                                      itemOpacity: 1,
                                    },
                                  },
                                ],
                              },
                            ]}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                            theme={{
                              textColor: isDarkMode ? "#ffffff" : "#3a3a3a",
                            }}
                          />{" "}*/}
                          {this.state.YearData && this.state.YearData.data ? (
                            <Column {...this.state.YearData} />
                          ) : null}
                        </Col>
                        <Col width="1"></Col>
                      </Row>
                    </Container>
                  </Tab>
                  {/*   <Tab eventKey="Building" title="Building">
                    <br />
                    <Building
                      table={this.state.defaultbuilding}
                      name={this.state.activeItemName}
                      project={this.state.project}
                      var={this.state.variables}
                    />
                          </Tab>*/}
                </Tabs>
              </Col>
            </Row>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.mitigation_info}
          closeIcon
          onClose={this.MitigationClose}
        >
          <Modal.Header>Flood Risk Reduction Level</Modal.Header>
          <Modal.Content>
            <div>
              <p>
                <b>
                  Please select appropriate flood risk reduction level from High
                  to Low on the pull-down manual by referring to the below
                  guidance.{" "}
                </b>
                <br />
                This function is to account for the flood risk reduction
                capacity of three flood mitigation measures that are near the
                asset and connect to the main flood control / stormwater
                management system of the area: a) manhole, b) catchpit, and c)
                underground flood tunnel. These mitigation measures are
                effective to reduce rainfall and river flooding, and they
                include any existing or to-be-built measures. It does not apply
                to storm surge flooding, as when there is a rise in sea level on
                the coastal side, the floodwater is very likely to block most
                nearby outlets of the stormwater management system.
                <br />
                <br /> The level of flood risk reduction depends on below two
                key factors and assumption: <br />
                1. Distance between any mitigation measure and the asset. To
                have a risk reduction impact, the closer the mitigation measure
                to the asset, the greater level of risk reduction. Any
                mitigation measure that is more than 20 meters far from the
                asset is assumed to have no risk reduction effect.
                <br /> 2. The number of available mitigation measures. The more
                the number of mitigation measures, the greater level of risk
                reduction. It can be in any combination of item a, b, and c as
                listed above, e.g., asset with two nearby manholes plus one
                nearby underground flood tunnel is counted as a total of 3
                measures for that asset.
                <br /> <br />
                A. <b>High level</b> of flood risk reduction – Either:
                <br /> i. Having at least <u>three measures</u> in place near
                the asset. Two measures both are at least within a distance of ≤
                10 meters, plus the remaining measure is at least within 15-20
                meters; or <br />
                ii. Having at least <u>three measures </u>in place near the
                asset. Two measures both are at least within a distance of 10-15
                meters, plus the remaining measure is at least within ≤ 10
                meters.
                <br /> <br />
                B. <b>Median level</b> of flood risk reduction – Either:
                <br /> i. Having at least <u>three measures</u> in place. Two
                measure both are at least within 15-20 meters, plus the
                remaining measure is at least within 10-15 meters; or
                <br /> ii. Having at least <u>two measures</u> in place near the
                asset. One measure is at least within ≤ 10 meters, plus another
                one is at least within 15-20 meters; or
                <br /> iii. Having at least <u>two measures</u> in place near
                the asset, and two measures both are at least within 10-15
                meters.
                <br />
                <br />
                C. <b>Low level</b> of flood risk reduction – Either: <br />
                i. Having at least <u>two measures </u>in place, and these two
                measures both are at least within 15-20 meters; or <br />
                ii. Having at least <u>one measure</u> in place, and this
                measure is at least within a distance of ≤ 10 meters.
                <br />
                <br /> Example: An asset has nearby two flood mitigation
                measures (no matter what combination of a) manhole, b) catchpit
                or c) underground flood tunnel). One measure (e.g., manhole) is
                within 10-15 meters and another measure (e.g., catchpit or
                another manhole) is at 15-20 meters far from the asset. Both
                measures are connected to the city-level stormwater management
                system. In this example, as it can’t fulfill the requirement of
                the median level of flood risk reduction of points B) ii and B)
                iii above, it falls into the category of low level of flood risk
                reduction.
              </p>
            </div>
          </Modal.Content>
        </Modal>
        <Modal open={this.state.Cvar} closeIcon onClose={this.CvarClose}>
          <Modal.Header>Climate Value at Risk</Modal.Header>
          <Modal.Content>
            <p>
              Climate value at risk (CVaR) is a measure for estimating the
              maximum amount of loss over a one year period due to co-occurring
              extreme events. CVaR can be thought of as a potential loss due to
              the exposure of an asset, displayed as a $ value or a percentage
              (%).
            </p>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.FilterInfo}
          closeIcon
          onClose={this.FilterInfoClose}
        >
          <Modal.Header>Advance Filter</Modal.Header>
          <Modal.Content>
            <p>
              Make use of the “Advance Filter” to view detailed analysis results
              of the same project under different variables (e.g., scenario and
              year), toggle to view the Climate Value at Risk in $ loss or as a
              percentage (%).
              <br />
              Simply select the desired variable/criteria, and press “Apply
              Filter”.
            </p>
          </Modal.Content>
        </Modal>
        <Modal open={this.state.lossopen} closeIcon onClose={this.lossClose}>
          <Modal.Content>
            <Col
              id="Intensity"
              lg={12}
              xs={12}
              sm={12}
              style={{ padding: "5%" }}
            >
              <Row className="mainheading">
                <p>
                  <span className={"dark-mode-theme-color-1"}>
                    Climate Variable Intensity
                  </span>
                </p>
              </Row>
              <Row>
                <Col></Col>
              </Row>
              <br />

              {this.state.hazard && this.state.risk_2020 ? (
                <Progress_bar
                  hazard={this.state.hazard}
                  newdata={this.state.risk_2020}
                  variable={this.state.variables}
                  activeIndex={this.state.activeIndex}
                />
              ) : null}
            </Col>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.percentopen}
          closeIcon
          onClose={this.percentClose}
        >
          <Modal.Content>
            <Col id="Loss" lg={12} sm={12} xs={12}>
              <Row className="mainheading">
                {" "}
                <p>
                  <span className={"dark-mode-theme-color-1"}>
                    Portfolio Losses
                  </span>
                </p>
              </Row>

              {this.state.losses["detailed"] ? (
                <div>
                  {" "}
                  {this.state.financial ? (
                    <LossComponent
                      data={this.state.losses}
                      newdata={this.state.losses_2020}
                    />
                  ) : (
                    <LossPercentComponent
                      data={this.state.losses}
                      newdata={this.state.losses_2020}
                    />
                  )}
                </div>
              ) : null}
            </Col>
          </Modal.Content>
        </Modal>
        <Modal open={this.state.heatopen} closeIcon onClose={this.heatClose}>
          <Modal.Content>
            <Col className="firstshadow" lg={12} xs={16} sm={16}>
              <Row className="mainheading">
                <p>
                  <span className={"dark-mode-theme-color-1"}>
                    Climate Risk Heat Map (0 to 100){" "}
                  </span>
                </p>
              </Row>
              <Row>
                <Col>
                  {this.state.heatmap ? (
                    <Classicheatmap
                      data={this.state.heatmap}
                      variable={this.state.variables}
                    />
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Modal.Content>
        </Modal>
        <Modal open={this.state.riskOpen} closeIcon onClose={this.RiskClose}>
          <Modal.Header>Climate Risk Index</Modal.Header>
          <Modal.Content>
            <Image src={index} />
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.filterOpen}
          closeIcon
          onClose={this.filterClose}
        >
          <Modal.Header className="advance">
            <h2>Advanced Filter Options</h2>
            <p>Filtering your data for more insight</p>
          </Modal.Header>
          <Modal.Content className="Filter">
            <Row>
              <Col lg={4} style={{ wordBreak: "break-word" }}>
                <p>Projects</p>
                <Form.Select
                  placeholder="Project"
                  onChange={(e, { value }) =>
                    this.handleChangePro(value, "project")
                  }
                  value={project}
                  options={options}
                  disabled={this.state.detailed}
                />
              </Col>

              <Col lg={4}>
                <p>Analysis Type</p>
                <Form.Select
                  placeholder="Analysis Type"
                  onChange={(e, { value }) =>
                    this.handleChangePro(value, "analysis")
                  }
                  value={analysis}
                  options={AnalysisOptions}
                />
              </Col>

              <Col lg={4}>
                {this.state.analysis == "rcp" ? (
                  <>
                    {" "}
                    <p>RCP</p>
                    <Form.Select
                      placeholder="RCP"
                      onChange={(e, { value }) =>
                        this.handleChangePro(value, "rcp")
                      }
                      value={rcp}
                      options={RcpOptions}
                      disabled={this.state.detailed}
                    />
                  </>
                ) : (
                  <>
                    <p>SSP</p>
                    <Form.Select
                      placeholder="SSP"
                      onChange={(e, { value }) =>
                        this.handleChangePro(value, "ssp")
                      }
                      value={ssp}
                      options={RcpOptions}
                    />
                  </>
                )}
              </Col>
            </Row>
            <Row>
              {/* <Col>
                <p>Count</p>
                <Form.Select
                  placeholder="count"
                  onChange={(e, { value }) =>
                    this.handleChangePro(value, "count")
                  }
                  value={count}
                  options={CountOptions}
                  disabled={this.state.detailed}
                />
                </Col>*/}

              <Col lg={4}>
                <p>Scenario Type</p>

                <Form.Select
                  placeholder="Scenario Type"
                  onChange={(e, { value }) =>
                    this.handleChangePro(value, "financial")
                  }
                  value={this.state.financial}
                  options={FinanceOptions}
                />
              </Col>

              <Col lg={4}>
                <p>Year</p>
                {this.state.analysis == "rcp" ? (
                  <Form.Select
                    placeholder="Year"
                    onChange={(e, { value }) =>
                      this.handleChangePro(value, "year")
                    }
                    value={year}
                    options={YearOptions}
                    disabled={this.state.detailed}
                  />
                ) : (
                  <Form.Select
                    placeholder="Year"
                    onChange={(e, { value }) =>
                      this.handleChangePro(value, "year")
                    }
                    value={year}
                    options={YearOptions_ssp}
                    disabled={this.state.detailed}
                  />
                )}
              </Col>

              <Col lg={4}>
                <p>Content Type</p>
                <Form.Select
                  placeholder="Content Type"
                  onChange={(e, { value }) =>
                    this.handleChangePro(value, "with_content")
                  }
                  value={this.state.with_content}
                  options={ContentOptions}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <p>
                  Account for Long-term Impact of Climate Change on Economic
                  Growth
                </p>
                <Form.Select
                  placeholder="Additional Factor"
                  onChange={(e, { value }) =>
                    this.handleChangePro(value, "adjusted_gdp")
                  }
                  value={this.state.adjusted_gdp}
                  options={GdpOptions}
                />
              </Col>

              <Col lg={4}>
                <p>Mitigation</p>
                <Form.Select
                  placeholder="Mitigation"
                  onChange={(e, { value }) =>
                    this.handleChangePro(value, "mitigation")
                  }
                  value={this.state.mitigation}
                  options={mitigationOptions}
                />
              </Col>
              {/*  <Col lg={4}>
                <p>Select Currency</p>
                <Dropdown
                  placeholder="Currency"
                  fluid
                  selection
                  options={currencyOptions}
                  value={this.state.currency}
                  onChange={(e, { value }) =>
                    this.handleChangePro(value, "currency")
                  }
                />
                </Col>*/}
              {/*  <Col lg={4}>
                <p>CVaR in Percentage</p>
                <Form.Select
                  placeholder="CVaR in Percentage"
                  onChange={(e, { value }) =>
                    this.handleChangePro(value, "use_valuation")
                  }
                  value={this.state.use_valuation}
                  options={valuationOptions}
                />
                </Col>*/}
              <p>
                Note for *: For the outputs under “Operational Loss Adjusted
                with Long-term Climate Change Impact”, in addition to the
                operational loss due to acute hazards, it includes loss
                associated with the impact from long-term climate change
                (chronic hazards related e.g., raising temperatures on labour
                productivity, long-term sea level rise causing erosion on
                coastline, etc).
              </p>
            </Row>
            <br />

            <br />
            {/* <Row>
              {Property != "NA" ? (
                <Col lg={4}>
                  <p>Property Type</p>
                  <Form.Select
                    placeholder="Property Types"
                    onChange={(e, { value }) =>
                      this.handleChangePro(value, "property_type")
                    }
                    value={property_type}
                    options={PropertyOptions}
                  />
                </Col>
              ) : null}
                  </Row>*/}
            <Button
              onClick={this.handleSubmitPro}
              style={{
                backgroundColor: "#18202F",
                color: "white",
                float: "right",
              }}
            >
              Apply Filter
            </Button>
            <br />
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.card_finding_1}
          onClose={this.handleCardClose}
          closeIcon
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            Maximum Exposure
          </Modal.Header>
          <Modal.Content>
            <p className="key_finding">
              Maximum exposure refers to the sum of accumulated asset values
              (physical exposure) of all assets plus the accumulated annual
              operational revenue (opera onal exposure) of all assets that are
              exposed to climate hazards. Any asset that is not exposed to any
              physical damage, its asset value and operational revenue would not
              be included under max exposure.
            </p>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.toolkit}
          onClose={this.handleCardClose}
          closeIcon
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            Adaptation Toolkits
          </Modal.Header>
          <Modal.Content>
            <p className="key_finding">
              <br />
              <ol>
                <li>
                  Adaptation Toolkits: Provide a guidance for overall
                  understanding of key concept in relation to adaptation and
                  resilience action, adaptation planning process, as well as
                  categories and example in general.
                </li>
              </ol>
            </p>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.card_finding_2}
          onClose={this.handleCardClose}
          closeIcon
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            Value of Transition Policies
          </Modal.Header>
          <Modal.Content>
            <p className="key_finding">
              reflects the cost-saving benefits from the reduced physical
              climate risk that will occur if appropriate steps are taken now to
              implement climate action and policies toward a net-zero emissions
              target. It is the difference in the portfolio Climate
              Value-at-Risk between that of a high emissions scenario (RCP
              8.5/SSP 5-8.5) and the net-zero emissions scenario (RCP 2.6/SSP
              1-2.6) for a specified time horizon.
            </p>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.card_finding_3}
          onClose={this.handleCardClose}
          closeIcon
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            Climate Value at Risk
          </Modal.Header>
          <Modal.Content>
            <p className="key_finding">
              reflects the maximum amount of loss expected to be incurred over a
              one-year period (for all climate hazards that affect the asset).
            </p>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.card_finding_4}
          onClose={this.handleCardClose}
          closeIcon
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            Climate Score
          </Modal.Header>
          <Modal.Content>
            <p className="key_finding">
              reflects the equally weighted average of climate hazard severity
              across all hazards and all assets analyzed within a portfolio.
            </p>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.key_finding_1}
          onClose={this.handleKeyClose}
          closeIcon
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            Key Findings
          </Modal.Header>
          <Modal.Content>
            {this.state.key_finding_data ? (
              <p className="key_finding">
                For {this.state.analysis.toUpperCase()}{" "}
                {this.state.analysis == "rcp" ? (
                  <span>{this.state.rcp}</span>
                ) : (
                  <span>{this.state.ssp}</span>
                )}
                , Climate Value at Risk of{" "}
                {this.state.key_finding_data.cvar_85_perc.toFixed(2)}% means
                that by {this.state.year} in any given one year{" "}
                {this.state.year == "2100" ? (
                  <span>
                    ({this.state.year - 19}-{this.state.year})
                  </span>
                ) : (
                  <span>
                    ({this.state.year - 9}-{this.state.year + 10})
                  </span>
                )}
                , we expect there to be a 1% chance that{" "}
                {this.state.key_finding_data.cvar_85_perc.toFixed(2)}% of the
                US${this.state.overall["Max_exposure"].toFixed(2)} Million
                results in a maximum loss of US${""}
                {this.state.losses["total"]["Total Loss"][0].toFixed(2)}{" "}
                Million.
              </p>
            ) : null}
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.key_finding_p}
          onClose={this.handleKeyClose}
          closeIcon
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            Portfolio Exposure related Loss Analysis
          </Modal.Header>
          <Modal.Content>
            <ul style={{ listStyleType: "disc", padding: "1%" }}>
              <li>
                Total CVaR in % = Total CVaR / Portfolio Exposure (i.e. Total
                asset value + total annual revenue income of ALL assets in the
                portfolio disregard if any asset is not exposed to any hazard
                damage.)
              </li>
              <li>
                Damage CVaR in % = Total Physical damage loss / total asset
                value for ALL assets in the portfolio
              </li>
              <li>
                Operational CVaR in % = Total operational loss / total annual
                revenue income for ALL assets in the portfolio
              </li>
            </ul>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.key_finding_t}
          onClose={this.handleKeyClose}
          closeIcon
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            Technical Insurance Premium
          </Modal.Header>
          <Modal.Content>
            <p>
              Technical Insurance Premium (TIP) is the annual expected cost of
              damage assuming all hazards are insured, which is equal to the
              physical loss of the asset, using the replacement cost as the
              basis. TIP in % is the TIP as a percentage of the replacement cost
              of the asset.
            </p>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.key_finding_2}
          onClose={this.handleKeyClose}
          closeIcon
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            Key Findings
          </Modal.Header>
          <Modal.Content>
            {this.state.hazard ? (
              <p className="key_finding">
                For the assets analyzed, the portfolio is exposed to chronic &
                acute climate events to the extent identified below. The red bar
                above each hazard indicates the severity of that hazard for the
                year 2020 (historical), and the blue bar indicates the severity
                of that hazard by the year under consideration (e.g., by the
                year{" "}
                {this.state.analysis == "rcp" ? (
                  <span>2030 / 2050</span>
                ) : (
                  <span>2030/2050/2100</span>
                )}
                ). Users can compare the length of the red bar with the blue bar
                to get a sense of how the risk severity for each hazard changes
                across years.
                <br />
                <br />
                {this.state.hazard.both["Typhoon"] ? (
                  <p>
                    <span className="variable_heading">
                      Typhoon : {this.state.hazard.both["Typhoon"][0]}
                      {this.state.hazard.both["Typhoon"][1]}
                    </span>{" "}
                    &nbsp; Typhoons bring intense wind gusts and rainfall that
                    may result in physical damage to assets, power outages and
                    business interruption. Typhoon risk models estimate the
                    spatial extent and wind speed intensity of a typhoon from
                    historic tropical cyclone track, temperature and wind speed
                    data.
                  </p>
                ) : null}
                <br />
                {this.state.hazard.both["Rainfall Flood"] ? (
                  <p>
                    <span className="variable_heading">
                      Rainfall Flood :{" "}
                      {this.state.hazard.both["Rainfall Flood"][0]}
                      {this.state.hazard.both["Rainfall Flood"][1]}
                    </span>{" "}
                    &nbsp; Rainfall flood refers to inundation resulting from
                    rainfall, elevation and climate change. Flood hazard
                    severity is derived from flood models, river bathymetry,
                    maximum rainfall in a 24-hour period, and extreme rainfall
                    events.​Intense precipitation may bring rainfall floods or
                    storm surges that result in physical damages to assets or
                    temporary facility closures.
                  </p>
                ) : null}
                <br />
                {this.state.hazard.both["River Flood"] ? (
                  <p>
                    <span className="variable_heading">
                      River Flood : {this.state.hazard.both["River Flood"][0]}
                      {this.state.hazard.both["River Flood"][1]}{" "}
                    </span>{" "}
                    &nbsp; River flood refers to inundation as a result of
                    overflows from a river channel. This may result in physical
                    damage to assets and business interruption. Flood hazard
                    information is obtained from in-house hydrology and
                    hydraulic models.​ 
                  </p>
                ) : null}
                <br />
                {this.state.hazard.both["Extreme heat"] ? (
                  <p>
                    {" "}
                    <span className="variable_heading">
                      Extreme Heat : {this.state.hazard.both["Extreme heat"][0]}
                      {this.state.hazard.both["Extreme heat"][1]}
                    </span>{" "}
                    &nbsp; Extreme Heat is experienced when humidity and
                    temperature are high for prolonged period. Extreme heat
                    values reflected are maximum values derived from the impacts
                    of climate change on temperature and relative humidity.
                    Prolonged extreme heat conditions may lead to undesirable
                    effects on health and well-being, productivity losses and
                    increased energy consumption.
                  </p>
                ) : null}
                <br />
                {this.state.hazard.both["Sea Level Rise"] ? (
                  <p>
                    {" "}
                    <span className="variable_heading">
                      Sea Level Rise :{" "}
                      {this.state.hazard.both["Sea Level Rise"][0]}
                      {this.state.hazard.both["Sea Level Rise"][1]}{" "}
                    </span>{" "}
                    &nbsp;Sea Level Rise refers to inundation estimated from
                    shoreline and elevation models. It considers mean sea level
                    projections, the affected catchment area near shorelines,
                    elevation models and forecasting analysis based on the
                    representative scenarios from Global Circulation
                    Models.​ Sea level rise as a chronic hazard may result in
                    inundation of areas or road networks surrounding an asset
                    over a long time horizon.
                  </p>
                ) : null}
                <br />
                {this.state.hazard.both["Storm Surge"] ? (
                  <p>
                    {" "}
                    <span className="variable_heading">
                      Storm Surge : {this.state.hazard.both["Storm Surge"][0]}
                      {this.state.hazard.both["Storm Surge"][1]}
                    </span>{" "}
                    &nbsp; Storm surge refers to the extreme rise in sea level
                    due to typhoons, typhoon tracks, elevations, and climate
                    change. This may result in physical damage to assets and
                    business interruption. Storm surge hazard inundation depths
                    are derived from hydrodynamic models. 
                  </p>
                ) : null}
                <br />
                {this.state.hazard.both["Snow Melt"] ? (
                  <p>
                    {" "}
                    <span className="variable_heading">
                      Snow Melt : {this.state.hazard.both["Snow Melt"][0]}
                      {this.state.hazard.both["Snow Melt"][1]}{" "}
                    </span>{" "}
                    &nbsp; Snowmelt is the surface runoff produced by melting
                    snow.  Snowmelt hazard severity is derived from terrain
                    characteristics, temperature degree days and Global Climate
                    Models.
                  </p>
                ) : null}
                <br />
                {this.state.hazard.both["Landslide"] ? (
                  <p>
                    {" "}
                    <span className="variable_heading">
                      Landslide : {this.state.hazard.both["Landslide"][0]}
                      {this.state.hazard.both["Landslide"][1]}{" "}
                    </span>{" "}
                    &nbsp; A landslide is a movement of a mass of rock, debris,
                    or earth down a slope. The hazard is calculated as a
                    frequency or annual probability of landslide. 
                  </p>
                ) : null}
                <br />
                {this.state.hazard.both["Drought"] ? (
                  <p>
                    {" "}
                    <span className="variable_heading">
                      Drought : {this.state.hazard.both["Drought"][0]}
                      {this.state.hazard.both["Drought"][1]}
                    </span>{" "}
                    <br />
                    Drought is a prolonged absence or marked deficiency of
                    precipitation or dry weather which results in water shortage
                    or a hydrological imbalance. Drought scores are derived from
                    the Standard Precipitation Index.
                    <br />
                  </p>
                ) : null}
                <br />
                <p>
                  Note :: Above hazard values are averages across all assets
                  within the portfolio
                </p>
              </p>
            ) : null}
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.key_finding_3}
          onClose={this.handleKeyClose}
          closeIcon
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            Key Findings
          </Modal.Header>
          <Modal.Content>
            <p className="key_finding">
              Users can compare the length of the red bar with the blue bar to
              visualize changes in the Climate Value-at-Risk (financial loss in
              dollar or % value) across dimensions.
            </p>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.info_table}
          onClose={this.handleKeyClose}
          closeIcon
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            Asset Table
          </Modal.Header>
          <Modal.Content>
            <p className="key_finding">
              Users can use the “Edit” function for each asset under the
              “Action” column to update different variables, including asset
              name, property type, area (single floor construction area in
              square meter), floors (total no. of floors), value (asset
              valuation per square meter), etc. So as long as the user is within
              its subscription period, the analysis results can be updated with
              more accurate internal information when this information is
              available later. However, the location information (latitude and
              longitude) can not be updated once the project is created. Simply
              amend the content of the variables and click “Save”, the analysis
              results will update accordingly.
            </p>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    errors: state.project.errors,
    project: state.project.list,
    feedback: state.feedback.feedback,
    building: state.feedback.building,
    detailyear: state.feedback.detailyear,
    isLoading: state.feedback.isLoading,
    assets: state.asset.asset,
    summary: state.feedback.summary,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addDashboard: (formdata) => {
      dispatch(dashboard.postDashboard(formdata));
    },
    getProjectList: (physical_risk) => {
      dispatch(project.getProjectList(physical_risk));
    },
    logout: () => {
      dispatch(auth.logout());
    },
    getDetailByYear: (formdata) => {
      dispatch(dashboard.getDetailByYear(formdata));
    },
    getAssets: () => {
      dispatch(asset.getAssets());
    },
    updateAsset: (formdata) => {
      dispatch(asset.updateAsset(formdata));
    },
    getSummary: (formdata) => {
      dispatch(dashboard.getSummary(formdata));
    },
    sspDashboard: (formdata) => {
      dispatch(dashboard.sspDashboard(formdata));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
